<template>
  <div>
    <!-- Toast is the pop-up that appears when the change is saved -->
    <Toast />

    <Datatable
      :value="students"
      ref="student_datatable"
      sortField="nom"
      sortOrder="1"
      :stripedRows="true"
      dataKey="person.id"
      v-model:filters="filters"
      :globalFilterFields="[
        'person.nom',
        'person.prenom',
        'referent',
        'statut_acad',
        'niveau_acad',
      ]"
      filterDisplay="menu"
      editMode="row"
      v-model:editingRows="editingRows"
      @row-edit-init="on_row_edit_init"
      @row-edit-cancel="on_row_edit_cancel"
      @row-edit-save="on_row_edit_save"
      exportFilename="datatable_eleve"
      csvSeparator=";"
      v-model:selection="selected_students"
      responsiveLayout="stack"
      class="p-datatable-sm"
      breakpoint="960px"
      :paginator="
        toggle_filter_by_niveau_acad *
        toggle_filter_by_referent *
        toggle_filter_by_up_to_date
      "
      :rows="20"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      :loading="loading"
      @filter="start_filter"
      @value-change="stop_loading"
    >
      <template #header>
        <div id="flex-jc-between">
          <!-- 
            ANN-LISTE_ELV-5 :
            Display an input zone for the user to write in what they are looking for.
            The text written here will be searched in all columns that are in the 
            Datatable's globalFilterFields option.
            -->
          <span id="datatable-searchbar" class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="Rechercher"
            />
          </span>

          <span style="text-align: left">
            <!-- 
            ANN-LISTE_ELV-11 :
            Diplay a button that calls the method to send a mail to every selected students
            -->
            <Button
              icon="pi pi-envelope"
              title="Envoyer un mail"
              @click="send_mail_to_list(selected_students)"
              style="margin-right: 5px"
            />

            <!-- 
                ANN-LISTE_ELV-9 :
                Display a button that import data dislpayed in the Datatable 
                (after search, filter, sort) in a .csv file.
            -->
            <Button
              icon="pi pi-file-excel"
              title="Export"
              @click="exportCSV($event)"
            />
            <!--
              ANN-LISTE_ELV-12 :
              Display a button that shows the form to update the local DB with
              the informations from Synapses.
             -->
            <Button
              label="Synchro Synapses"
              class="button-success"
              icon="pi pi-refresh"
              title="Mettre à jour les élèves"
              @click="display_update_students = true"
              style="margin-right: 5px"
            />
          </span>
        </div>
      </template>

      <!-- 
        ANN-LISTE_ELV-11 : 
        Puts checkboxes in every row so that the admin can select the students he wants
        to send a mail to.
      -->
      <Column selectionMode="multiple" headerStyle="width: 3em" />

      <Column header="Photo" field="person.photo" :exportable="false">
        <template #body="std_photo">
          <div v-if="std_photo.data.person.droit_image">
            <img
              v-lazy="get_img_url(std_photo.data.person['photo'])"
              alt="photo élève"
              width="45"
              height="45"
            />
          </div>
          <div v-else>
            <em class="pi pi-user" style="font-size: 3.5rem" />
          </div>
        </template>
        <template #editor="std_photo">
          <div v-if="std_photo.data.person.droit_image">
            <img
              v-lazy="get_img_url(std_photo.data.person['photo'])"
              alt="photo élève"
              width="45"
              height="45"
            />
          </div>
          <div v-else>
            <em class="pi pi-user" style="font-size: 3.5rem" />
          </div>
        </template>
      </Column>
      <Column
        v-for="col of columns"
        :field="col.field"
        :header="col.header"
        :sortable="col.sortable"
        :hidden="col.hidden"
        :key="col.field"
        :exportable="col.exportable"
      ></Column>
      <Column
        field="niveau_acad"
        header="Année"
        sortable="true"
        filterField="niveau_acad"
        sortField="niveau_acad"
        :showFilterMatchModes="false"
        :filterMenuStyle="{ width: '14rem' }"
      >
        <template #filter="{ filterModel }">
          <!-- ANN-LISTE_ELV-8 :
                Displays an icon in the column header. When clicked, an overlay
                that lets the admin select a referent or a blank choice opens-up.
                If a referent is selected, the datatable will only shows the students
                with the correct referent. The datatable can be empty if no student
                have one referent.
                -->
          <Dropdown
            v-model="filterModel.value"
            :options="list_niveau_acad"
            optionLabel="label"
            optionValue="label"
            placeholder="Choisissez un niveau académique"
            :showClear="true"
          >
            <template #value="niveau_acad">
              <div v-if="niveau_acad.value">
                {{ niveau_acad.value }}
              </div>
              <span v-else>{{ niveau_acad.placeholder }}</span>
            </template>
            <template #option="niveau_acad">
              {{ niveau_acad.option.label }}
            </template>
          </Dropdown>
        </template>
        <template #filterclear="{ filterCallback }">
          <Button
            type="button"
            label="Réinitialiser"
            @click="
              [filterCallback(), (this.toggle_filter_by_niveau_acad = true)]
            "
            class="p-button p-component p-button-outlined p-button-sm"
          ></Button>
        </template>
        <template #filterapply="{ filterCallback }">
          <Button
            type="button"
            label="Appliquer"
            @click="
              [filterCallback(), (this.toggle_filter_by_niveau_acad = false)]
            "
            class="p-button p-component p-button-sm"
          ></Button>
        </template>
      </Column>
      <Column
        field="id_fiche_synapses"
        header="Synapses"
        excludeGlobalFilter="true"
      >
        <template #body="id_fiche">
          <Button
            icon="pi pi-external-link"
            title="Fiche Synapses"
            v-on:click="open_synapses(id_fiche.data['id_fiche_synapses'])"
            class="p-button-no-background"
          />
        </template>
        <template #editor="id_fiche">
          <Button
            icon="pi pi-external-link"
            title="Fiche Synapses"
            v-on:click="open_synapses(id_fiche.data['id_fiche_synapses'])"
            class="p-button-no-background"
          />
        </template>
      </Column>
      <!-- 
        ANN-LISTE_ELV-10 :
        Column with a mail icon. If the icon is clicked, the user's webmail
        opens with the receiver of the message already set.
      -->
      <Column header="Contacter" excludeGlobalFilter="true">
        <template #body="mail_body">
          <Button
            icon="pi pi-envelope"
            title="Envoyer un mail"
            v-on:click="send_mail_to_one([mail_body.data.person])"
            class="p-button-no-background"
          />
        </template>
        <template #editor="mail_body">
          <Button
            icon="pi pi-envelope"
            title="Envoyer un mail"
            v-on:click="send_mail_to_one([mail_body.data.person])"
            class="p-button-no-background"
          />
        </template>
      </Column>

      <!-- 
        ANN-LISTE_ELV-13 :
        Column with a summary of the state of every appointment
        of the student. The summary contains the title and
        the status of the appointment.
      -->
      <Column
        header="RDV"
        :excludeGlobalFilter="true"
        bodyStyle="text-align:left;font-size:0.8em;"
        :exportable="false"
      >
        <template #body="student">
          <span
            class="appointment"
            v-for="appointment in student.data['appointments']"
            :key="appointment.id"
          >
            - {{ appointment.appointment_reference.titre_rendez_vous }}-{{
              appointment.appointment_reference.niveau_acad
            }}
            :
            <i
              v-if="appointment.statut_fiche == 'validated'"
              class="pi pi-check"
              style="color: green"
              title="Validée par le référent"
            />
            <i
              v-if="appointment.statut_fiche == 'not_validated'"
              class="pi pi-replay"
              style="color: #ef7d00"
              title="En cours de validation par le référent"
            />
            <i
              v-if="appointment.statut_fiche == 'not_exists'"
              class="pi pi-exclamation-circle"
              style="color: #ffffff"
              title="N'existe pas"
            />
            <Button
                v-if="appointment.statut_fiche == 'validated' || appointment.statut_fiche == 'not_validated'"
              :key="appointment.fiche_deposee_par_eleve"
              icon="pi pi-download"
              label=""
              class="p-button-sm"
              title="Télécharger"
              style="margin:0px;padding:0px;"
              @click="
                download_appointment_file(
                  appointment.id,
                  appointment.fiche_deposee_par_eleve
                )
              "
            />
            <i
              v-if="
                appointment.statut_fiche == 'not_uploaded' &&
                date_fiche_passed(
                  appointment.appointment_reference.date_fin,
                  appointment.appointment_reference.date_debut
                ) == 'inlate'
              "
              class="pi pi-exclamation-circle"
              style="color: red"
              title="Non validé par le référent, en retard!"
            />
            <i
              v-if="
                appointment.statut_fiche == 'not_uploaded' &&
                date_fiche_passed(
                  appointment.appointment_reference.date_fin,
                  appointment.appointment_reference.date_debut
                ) == 'todo'
              "
              class="pi pi-exclamation-circle"
              style="color: #ef7d00"
              title="Non validé par le référent"
            />
            <i
              v-if="
                appointment.statut_fiche == 'not_uploaded' &&
                date_fiche_passed(
                  appointment.appointment_reference.date_fin,
                  appointment.appointment_reference.date_debut
                ) == 'toosoon'
              "
              class="pi pi-exclamation-circle"
              style="color: green"
              title="Non validé par le référent, période non démarrée"
            />

            <br />
          </span>
        </template>
        <template #editor="student">
          <span
            class="appointment"
            v-for="appointment in student.data['appointments']"
            :key="appointment.id"
          >
            - {{ appointment.appointment_reference.titre_rendez_vous }}-{{
              appointment.appointment_reference.niveau_acad
            }}
            :
            <i
              v-if="appointment.statut_fiche == 'validated'"
              class="pi pi-check"
              style="color: green"
              title="Validée par le référent"
            />
            <i
              v-if="appointment.statut_fiche == 'not_validated'"
              class="pi pi-replay"
              style="color: #ef7d00"
              title="En cours de validation par le référent"
            />
            <i
              v-if="appointment.statut_fiche == 'not_exists'"
              class="pi pi-exclamation-circle"
              style="color: #ffffff"
              title="N'existe pas"
            />
            <Button
                v-if="appointment.statut_fiche == 'validated' || appointment.statut_fiche == 'not_validated'"
              :key="appointment.fiche_deposee_par_eleve"
              icon="pi pi-download"
              label=""
              class="p-button-sm"
              style="margin:0px;padding:0px"
              title="Télécharger"
              @click="
                download_appointment_file(
                  appointment.id,
                  appointment.fiche_deposee_par_eleve
                )
              "
            />
            <i
              v-if="
                appointment.statut_fiche == 'not_uploaded' &&
                date_fiche_passed(
                  appointment.appointment_reference.date_fin,
                  appointment.appointment_reference.date_debut
                ) == 'inlate'
              "
              class="pi pi-exclamation-circle"
              style="color: red"
              title="Non validé par le référent, en retard!"
            />
            <i
              v-if="
                appointment.statut_fiche == 'not_uploaded' &&
                date_fiche_passed(
                  appointment.appointment_reference.date_fin,
                  appointment.appointment_reference.date_debut
                ) == 'todo'
              "
              class="pi pi-exclamation-circle"
              style="color: #ef7d00"
              title="Non validé par le référent"
            />
            <i
              v-if="
                appointment.statut_fiche == 'not_uploaded' &&
                date_fiche_passed(
                  appointment.appointment_reference.date_fin,
                  appointment.appointment_reference.date_debut
                ) == 'toosoon'
              "
              class="pi pi-exclamation-circle"
              style="color: green"
              title="Non validé par le référent, période non démarrée"
            />

            <br />
          </span>
        </template>
      </Column>

      <!-- 
        ANN-LISTE_ELV-13 :
        Displays, for each appoitment_number an
        icon depending on the status of the appointment.
      -->
      <Column
        v-for="appointment_number of list_appointment_number"
        :key="appointment_number"
        :header="`RDV ${appointment_number}`"
        :filterField="`appointment_${appointment_number}.statut_fiche`"
        excludeGlobalFilter="False"
        :showFilterMatchModes="false"
        :field="`appointment_${appointment_number}.statut_fiche`"
        :filterMenuStyle="{ width: '14rem' }"
        :exportable="true"
      >
        <template #filter="{ filterModel }">
          <!--
            ANN-LISTE_ELV-14 :
            Filter for each RDV column. Enables filtering for a specific
            value for each appointment
           -->
          <Dropdown
            v-model="filterModel.value"
            :options="list_statut_fiche"
            optionLabel="label"
            optionValue="value"
            placeholder="Aucun filtre"
            :showClear="true"
          >
            <template #value="statut_fiche">
              <div v-if="statut_fiche.value">
                {{ get_label_statut(statut_fiche.value) }}
              </div>
              <span v-else>{{ statut_fiche.placeholder }}</span>
            </template>
            <template #option="statut_fiche">
              {{ statut_fiche.option.label }}
            </template>
          </Dropdown>
        </template>
        <template #filterclear="{ filterCallback }">
          <Button
            type="button"
            label="Réinitialiser"
            @click="
              [filterCallback(), (this.toggle_filter_by_up_to_date = true)]
            "
            class="p-button p-component p-button-outlined p-button-sm"
          ></Button>
        </template>
        <template #filterapply="{ filterCallback }">
          <Button
            type="button"
            label="Appliquer"
            @click="
              [filterCallback(), (this.toggle_filter_by_up_to_date = false)]
            "
            class="p-button p-component p-button-sm"
          ></Button>
        </template>
        <template #body="student">
          <!-- 
            ANN-LISTE_ELV-13 :
            Displays for each appointment of the student an icon
            depending on the current state of the appointment.
            The color of the icon depends on the stored state of
            the appointment file :
              - validated, the referent approved the file
              - not_validated, the student sent the appointment file
                but the referent has not yet validated it
              - not_uploaded, the student did not send an appointment
                file
            The icon depends on wether the student is late, early
            or in time for submitting the file.
          -->
          RDV {{student.data[`appointment_${appointment_number}`]
                  .appointment_reference.niveau_acad}}
          <i
            v-if="
              typeof student.data[`appointment_${appointment_number}`] !==
                'undefined' &&
              student.data[`appointment_${appointment_number}`].statut_fiche ==
                'validated'
            "
            class="pi pi-check"
            style="color: green"
            title="Validée par le référent"
          />
          <i
            v-if="
              typeof student.data[`appointment_${appointment_number}`] !==
                'undefined' &&
              student.data[`appointment_${appointment_number}`].statut_fiche ==
                'not_validated'
            "
            class="pi pi-replay"
            style="color: #ef7d00"
            title="En cours de validation par le référent"
          />
          <i
            v-if="
              typeof student.data[`appointment_${appointment_number}`] !==
                'undefined' &&
              student.data[`appointment_${appointment_number}`].statut_fiche ==
                'not_exists'
            "
            class="pi pi-exclamation-circle"
            style="color: #FFFFFF"
            title="N'existe pas"
          />
          <i
            v-if="
              typeof student.data[`appointment_${appointment_number}`] !==
                'undefined' &&
              student.data[`appointment_${appointment_number}`].statut_fiche ==
                'not_uploaded' &&
              date_fiche_passed(
                student.data[`appointment_${appointment_number}`]
                  .appointment_reference.date_fin,
                student.data[`appointment_${appointment_number}`]
                  .appointment_reference.date_debut
              ) == 'inlate'
            "
            class="pi pi-exclamation-circle"
            style="color: red"
            title="Non validé par le référent, en retard!"
          />
          <i
            v-if="
              typeof student.data[`appointment_${appointment_number}`] !==
                'undefined' &&
              student.data[`appointment_${appointment_number}`].statut_fiche ==
                'not_uploaded' &&
              date_fiche_passed(
                student.data[`appointment_${appointment_number}`]
                  .appointment_reference.date_fin,
                student.data[`appointment_${appointment_number}`]
                  .appointment_reference.date_debut
              ) == 'todo'
            "
            class="pi pi-exclamation-circle"
            style="color: #ef7d00"
            title="Non validé par le référent"
          />
          <i
            v-if="
              typeof student.data[`appointment_${appointment_number}`] !==
                'undefined' &&
              student.data[`appointment_${appointment_number}`].statut_fiche ==
                'not_uploaded' &&
              date_fiche_passed(
                student.data[`appointment_${appointment_number}`]
                  .appointment_reference.date_fin,
                student.data[`appointment_${appointment_number}`]
                  .appointment_reference.date_debut
              ) == 'toosoon'
            "
            class="pi pi-exclamation-circle"
            style="color: green"
            title="Non validé par le référent, période non démarrée"
          />
        </template>
        <template #editor="student">
          <i
            v-if="
              typeof student.data[`appointment_${appointment_number}`] !==
                'undefined' &&
              student.data[`appointment_${appointment_number}`].statut_fiche ==
                'validated'
            "
            class="pi pi-check"
            style="color: green"
            title="Validée par le référent"
          />
          <i
            v-if="
              typeof student.data[`appointment_${appointment_number}`] !==
                'undefined' &&
              student.data[`appointment_${appointment_number}`].statut_fiche ==
                'not_validated'
            "
            class="pi pi-replay"
            style="color: #ef7d00"
            title="En cours de validation par le référent"
          />
          <i
            v-if="
              typeof student.data[`appointment_${appointment_number}`] !==
                'undefined' &&
              student.data[`appointment_${appointment_number}`].statut_fiche ==
                'not_uploaded' &&
              date_fiche_passed(
                student.data[`appointment_${appointment_number}`]
                  .appointment_reference.date_fin,
                student.data[`appointment_${appointment_number}`]
                  .appointment_reference.date_debut
              ) == 'inlate'
            "
            class="pi pi-exclamation-circle"
            style="color: red"
            title="Non validé par le référent, en retard!"
          />
          <i
            v-if="
              typeof student.data[`appointment_${appointment_number}`] !==
                'undefined' &&
              student.data[`appointment_${appointment_number}`].statut_fiche ==
                'not_uploaded' &&
              date_fiche_passed(
                student.data[`appointment_${appointment_number}`]
                  .appointment_reference.date_fin,
                student.data[`appointment_${appointment_number}`]
                  .appointment_reference.date_debut
              ) == 'todo'
            "
            class="pi pi-exclamation-circle"
            style="color: #ef7d00"
            title="Non validé par le référent"
          />
          <i
            v-if="
              typeof student.data[`appointment_${appointment_number}`] !==
                'undefined' &&
              student.data[`appointment_${appointment_number}`].statut_fiche ==
                'not_uploaded' &&
              date_fiche_passed(
                student.data[`appointment_${appointment_number}`]
                  .appointment_reference.date_fin,
                student.data[`appointment_${appointment_number}`]
                  .appointment_reference.date_debut
              ) == 'toosoon'
            "
            class="pi pi-exclamation-circle"
            style="color: green"
            title="Non validé par le référent, période non démarrée"
          />
        </template>
      </Column>
      <Column
        field="referent.person.nom"
        header="Référent"
        sortable="true"
        filterField="referent.person.id"
        sortField="referent"
        :showFilterMatchModes="false"
        :filterMenuStyle="{ width: '14rem' }"
        bodyStyle="text-align: center"
        :key="referent"
        :exportable="true"
      >
        <template #body="referent_body">
          <!-- ANN-LISTE_ELV-7 :
                Displays an icon and the name of the student's referent (if he has one).
                The admin can click on the icon to delete the link between the student
                and the ref. To prevent misclicks, a pop-up opens up asking the admin
                to confirm the deletion before sending any info to Django.
                -->
          <DeleteRefStdLink
            :student_data="referent_body.data"
            @get_students="this.$emit('get_students')"
          />
        </template>

        <template #filter="{ filterModel }">
          <!-- ANN-LISTE_ELV-8 :
                Displays an icon in the column header. When clicked, an overlay
                that lets the admin select a referent or a blank choice opens-up.
                If a referent is selected, the datatable will only shows the students
                with the correct referent. The datatable can be empty if no student
                have one referent.
                -->
          <Dropdown
            v-model="filterModel.value"
            :options="referents_names"
            optionLabel="label"
            optionValue="value"
            placeholder="Choisissez un référent"
            :filter="true"
            filterPlaceholder="Rechercher un référent"
            :showClear="true"
          >
            <template #value="ref">
              <div v-if="ref.value">
                {{ get_referent_name(ref.value) }}
              </div>
              <span v-else>{{ ref.placeholder }}</span>
            </template>
            <template #option="ref">
              {{ ref.option.label }}
            </template>
          </Dropdown>
        </template>
        <template #filterclear="{ filterCallback }">
          <Button
            type="button"
            label="Réinitialiser"
            @click="[filterCallback(), (this.toggle_filter_by_referent = true)]"
            class="p-button p-component p-button-outlined p-button-sm"
          ></Button>
        </template>
        <template #filterapply="{ filterCallback }">
          <Button
            type="button"
            label="Appliquer"
            @click="
              [filterCallback(), (this.toggle_filter_by_referent = false)]
            "
            class="p-button p-component p-button-sm"
          ></Button>
        </template>
        <!-- 
        ANN-LISTE_ELV-6 :
        The column that display the student's referent. This is column can be edited
        when an admin clicks on the row's rightmost button. The edit form is a dropdown
        menu with a search bar. The dropdown's content is a list of all the referents
        + an empty ref in case the admin needs to remove a referent-student link.
        -->
        <template #editor="editor">
          <ChangeReferent
            :referents="referents"
            :editor_data="editor.data"
          ></ChangeReferent>
        </template>
      </Column>
      <!-- 
        ANN-LISTE_ELV-6 : 
        Display a button that toggles row editing.
        -->
      <Column :rowEditor="true"></Column>
    </Datatable>

    <div id="update_students">
      <UpdateStudents
        :display_update_students="display_update_students"
        @get_students="this.$emit('get_students')"
      />
    </div>
  </div>
</template>

<script>
import ChangeReferent from "./ChangeReferent.vue";
import DeleteRefStdLink from "./DeleteRefStdLink.vue";
import UpdateStudents from "./UpdateStudents.vue";

import moment from "moment";
import axios from "axios";

export default {
  props: [
    "students",
    "columns",
    "referents",
    "referents_names",
    "loading",
    "list_appointment_number",
    "filters",
  ],
  components: { ChangeReferent, DeleteRefStdLink, UpdateStudents },

  data() {
    return {
      /*
      ANN-LISTE_ELV-6 :
      Variables needed to edit a student's referent : 
      - editingRows is the index of rows that are being edited
      - original_rows is a copy of all the rows that are being edited (in case we cancel the edit)
      */
      editingRows: [],
      original_rows: {},
      /*
      ANN-LISTE_ELV-8 :
      referent_filter is the name of the referent chosen in the dropdown menu.
      */
      referent_filter: null,
      /*
      ANN-LISTE_ELV-11 :
      List of all of the selected rows.
      */
      selected_students: [],
      display_update_students: false,
      toggle_filter_by_referent: true,
      toggle_filter_by_niveau_acad: true,
      toggle_filter_by_up_to_date: true,
      list_niveau_acad: [{ label: "1A" }, { label: "2A" }, { label: "3A" }],
      list_statut_fiche: [
        { label: "Non déposé", value: "not_uploaded" },
        { label: "Déposé, non validé", value: "not_validated" },
        { label: "Validé", value: "validated" },
        { label: "N'existe pas", value: "not_exists" },

      ],
    };
  },
  methods: {
    get_img_url(idimg) {
      var url = `https://referents-referes.mines-albi.fr/static/img/${idimg}.jpg`;
      return url;
    },

    on_row_edit_init(event) {
      /* When an admin clicks on the row edit button, this function saves the initial data 
      in case the edit is canceled */
      this.original_rows[event.index] = { ...this.students[event.index] };
      /* Only one row can be edited at one time. */
      if (this.editingRows.length >= 2) {
        this.editingRows.shift();
      }
    },

    on_row_edit_cancel(event) {
      /*  If the admin cancels the edit the rows return to what it was before. */
      this.students[event.index] = this.original_rows[event.index];
    },

    on_row_edit_save(event) {
      /* ANN-LISTE_ELV-6 :
        Method that is used when the admin confirm the changes in a row. 
        This method checks if a referent has been selected then send a request
        to django with the student's and the new referent's id.
        */
      var ref_id = event.data.selected_referent.id;
      var std_id = event.data.person.id;
      axios
        .patch(
          process.env.BASE_URL + "api/annuaire/students/patch-ref/",
          {
            std_id: std_id,
            ref_id: ref_id,
          },
          {
            headers: {
              "X-CSRFToken": this.$store.getters.csrf_token,
            },
          }
        )
        .then(() => {
          this.$emit("get_students");
          this.$toast.add({
            severity: "success",
            summary: "Modifications enregistrées.",
            life: 3000,
          });
        });
    },

    start_filter() {
      this.$emit("change_loading_state", true);
    },

    stop_loading() {
      this.$emit("change_loading_state", false);
    },

    exportCSV() {
      /* Export the data to csv */
      this.$refs.student_datatable.exportCSV();
    },
send_mail_to_one(person) {
      /*
        ANN-LISTE_ELV-11 : 
        Method that add send a mail to every person in the
        var list_person.
      */
      var mail_receiver = "";
      for (var ind_person in person) {
        mail_receiver += person[ind_person]["mail"];
        mail_receiver += ",";
      }
      if (mail_receiver != "") {
        window.location.href = `mailto:?bcc=${mail_receiver}`;
      }
    },

    send_mail_to_list(list_person) {
      /*
        ANN-LISTE_ELV-11 : 
        Method that add send a mail to every person in the
        var list_person.
      */
      var mail_receiver = "";
      for (var i = 0; i < list_person.length; i++) {
        var student = list_person[i];
        mail_receiver += student.person.mail;
        mail_receiver += ",";
      }
      if (mail_receiver != "") {
        window.location.href = `mailto:?bcc=${mail_receiver}`;
      }
    },

    get_referent_name(value) {
      for (const name of this.referents_names) {
        if (name["value"] == value) {
          return name["label"];
        }
      }
    },

    get_label_statut(value) {
      /*
        ANN-LISTE_ELV-14 :
        Function to link the displayed status of the appointment (in French)
        and the stored value of the appointment (in English) :
          "Non déposé" -> "not_uploaded"
          "Déposé, non validé" -> "not_validated"
          "Validé" -> "validated"
       */
      for (const statut_fiche of this.list_statut_fiche) {
        if (statut_fiche["value"] == value) {
          return statut_fiche["label"];
        }
      }
    },

    open_synapses(id_fiche) {
      window.open(
        `https://synapses.mines-albi.fr/dossier-pedagogique/${id_fiche}`,
        "_blank"
      );
    },

    date_fiche_passed(limit_date, start_date) {
      if (moment().toDate() < moment(start_date, "YYYY-MM-DD").toDate()) {
        return "toosoon";
      } else if (
        moment().toDate() < moment(limit_date, "YYYY-MM-DD").toDate()
      ) {
        return "todo";
      } else {
        return "inlate";
      }
    },

    replace_mobilite() {
      const elements = document.querySelectorAll(".p-column-title");
      for (const element of elements) {
        element.innerHTML = element.innerHTML.replace("RDV 0", "RDV Mob");
      }
    },

    download_appointment_file(id_appointment, file) {
      /*
      FREF-REFERE-6 :
      Method to download a blank appointment file.
       */
      var filename = file.split("/media/");
      axios({
        method: "get",
        url:
          process.env.BASE_URL +
          "api/appointments/appointment/serve-appointment-document/",
        headers: { appointment: id_appointment },
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename[1]); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      //window.location.href = "api/appointments/appointment/serve_appointment_document/" + id;
    },
  },

  updated() {
    this.$nextTick(function () {
      this.replace_mobilite();
    });
  },
};
</script>

<style lang="scss">
#datatable-searchbar {
  text-align: right;
}
</style>