<template>
  <div class="home" v-if="this.$store.getters.user">
    <h1 class="title">Ma Fiche Référent</h1>

    <hr />

    <Toast />

    <div class="columns is-centered">
      <div id="referes_datatable" class="column is-11">
        <Editor v-model="edited_fiche_ref" editorStyle="">
          <!--
            ANN-FICHE_REF-4 :
            The following are all options available in the text
            editor's toolbar.
            -->
          <template #toolbar>
            <div class="ql-formats">
              <span style="flex: 0 0 94%">
                <select class="ql-size">
                  <option label="Normal" />
                  <option label="Large" value="large" />
                </select>
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-link" />
                <button class="ql-list" value="ordered" />
                <button class="ql-list" value="bullet" />
                <select class="ql-align">
                  <option value="" />
                  <option value="center" />
                  <option value="right" />
                  <option value="justify" />
                </select>
              </span>
              <span style="flex: 1">
                <!-- 
                  ANN-FICHE_REF-5 :
                  Display a button to save the changes made and one to
                  change back the text editor to it's state before
                  modifications.
                  -->
                <Button
                  icon="pi pi-save"
                  title="Sauvegarder les modifications"
                  v-on:click="save_fiche_ref()"
                  class="save_fiche"
                />
                <Button
                  icon="pi pi-times"
                  title="Annuler les modifications"
                  v-on:click="init_edited_fiche_ref()"
                  class="cancel_fiche"
                />
              </span>
            </div>
          </template>
        </Editor>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return { edited_fiche_ref: null, save_status: null };
  },

  created() {
    this.init_edited_fiche_ref();
  },

  methods: {
    init_edited_fiche_ref() {
      /* ANN-FICHE_REF-4 :
        Changes the content of the text editor to be the same as what
        is stored in the DB.
        */
      this.edited_fiche_ref = this.$store.getters.user["fiche_ref"];
    },

    save_fiche_ref() {
      /* ANN-FICHE_REF-5 :
        Saves the new content of the text editor in the Vuex store and in
        the DB. A card is temporarily displayed on the UI if the DB can
        accept the modifications (enough space to save the text + html).
        If the DB can't save the new fiche, a card is also temporarily
        displayed on screen.
        */
      axios
        .patch(
          process.env.BASE_URL + "api/annuaire/referents/change-fiche-ref/",
          { new_fiche_ref: this.edited_fiche_ref },
          {
            headers: {
              "X-CSRFToken": this.$store.getters.csrf_token,
            },
          }
        )
        .then((response) => {
          if (response.data["detail"] == "Status: 200") {
            this.$store.dispatch("change_fiche_ref", this.edited_fiche_ref);
            this.$toast.add({
              severity: "success",
              summary: "Modifications enregistrées.",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Votre fiche est trop longue.",
              life: 3000,
            });
          }
          this.save_status = response.data["detail"];
        });
    },
  },
};
</script>

<style lang="scss">
.p-button.p-component.p-button-icon-only.save_fiche {
  color: white;
  background: #689f38;
  display: flex;
  vertical-align: middle;
}
.p-button.p-component.p-button-icon-only.save_fiche:hover {
  color: #689f38;
  background: transparent;
  border: 1px solid #689f38;
}
.p-button.p-component.p-button-icon-only.save_fiche:focus {
  box-shadow: 0 0 0 0.1rem #689f38;
}

.p-button.p-component.p-button-icon-only.cancel_fiche {
  color: white;
  background: #d32f2f;
  display: flex;
  vertical-align: middle;
}
.p-button.p-component.p-button-icon-only.cancel_fiche:hover {
  color: #d32f2f;
  background: transparent;
  border: 1px solid #d32f2f;
}
.p-button.p-component.p-button-icon-only.cancel_fiche:focus {
  box-shadow: 0 0 0 0.1rem #d32f2f;
}
</style>