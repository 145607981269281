<template>
  <div>
    <!-- 
        ANN-LISTE_REF-11 :
        Pop up dialog that displays the first and last name
        of all the selected referents. Also displays a confirmation
        and cancel button before deleting them.
    -->

    <Toast />

    <Dialog
      v-model:visible="display_del_referent"
      :modal="true"
      header="Supprimer un Référent"
      :style="{ width: '450px' }"
      @show="open_form"
      @hide="hide_form"
      class="p-fluid"
    >
      <div>
        Les référents suivant vont être supprimés de la base de données :
      </div>

      <!-- Displays the names of all selected referents -->
      <div
        v-for="referent in selected_referent"
        v-bind:key="referent['id']"
        style="text-align: center"
      >
        {{ referent["nom"] }} {{ referent["prenom"] }}
      </div>

      <!-- Buttons in the footer of the pop-up -->
      <template #footer>
        <Button
          label="Supprimer"
          icon="pi pi-check"
          class="p-button-success"
          @click="delete_referent"
        />
        <Button
          label="Annuler"
          icon="pi pi-times"
          class="p-button-danger"
          @click="hide_form"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["display_del_referent", "selected_referent"],

  data() {
    return {};
  },

  methods: {
    hide_form() {
      /* Method that hides the pop up if the user clicks either
      outside the box, on the cross or on the cancel button. */
      this.$parent.display_del_referent = false;
    },

    delete_referent() {
      /* 
        ANN-LISTE_REF-11 :
        Method that send a request to Django with the list of
        all the selected referents. If Django returns a success
        displays a green Toast to inform the user and calls the API 
        to update the list of referent on screen. Else, displays
        a red toast with a warning.
        */
      if (this.selected_referent) {
        axios
          .patch(
            process.env.BASE_URL + "api/annuaire/referents/delete/",
            { selected_referent: this.selected_referent },
            {
              headers: {
                "X-CSRFToken": this.$store.getters.csrf_token,
              },
            }
          )
          .then((response) => {
            if (response.data["detail"] == "Status: 200") {
              this.$parent.display_del_referent = false;
              this.$toast.add({
                severity: "success",
                summary: "Modifications enregistrées.",
                life: 3000,
              });
              this.$emit("get_referents");
              this.$parent.selected_persons = [];
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Modifications impossibles.",
                life: 3000,
              });
            }
          });
      }
    },
  },
};
</script>