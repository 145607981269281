<template>
  <div class="home" v-if="this.$store.getters.user">
    <hr />
    <div class="column is-12">
      <div class="columns is-centered">
        <div class="column is-4">
          <div id="my_ref" v-if="referent">
            <!-- 
                FREF-REFERE-1 : 
                Displays the user's referent's informations on the UI.
            -->
            <h1 class="title">Mon Référent</h1>
            <div id="info_ref" style="text-align: center">
              <span v-if="referent['droit_image']" style="margin-right: 5px">
                <img
                  :src="
                    './static/img/' +
                    referent['photo'] +
                    '.jpg'
                  "
                  alt="photo"
                  width="60"
                  height="60"
                />
              </span>
              <span v-else style="margin-right: 5px">
                <em class="pi pi-user" style="font-size: 3.5rem" />
              </span>
              <span class="ref_name" style="margin-left: 5px">
                <ul>
                  <li>
                    {{ referent["civilite"] }} {{ referent["nom"] }}
                    {{ referent["prenom"] }}
                  </li>
                  <li>{{ referent["service"] }}</li>
                </ul>
              </span>
            </div>
            <div>
              <!-- 
                    FREF-REFERE-3 :
                    Display a button that opens the user's webmail
                    with the receiver already set.
                -->
              <Button
                icon="pi pi-envelope"
                label="Envoyer un mail"
                title="Envoyer un mail"
                @click="send_mail(referent)"
                style="margin-top: 5px; color: #fff; background: #ef7d00"
              />
            </div>
            <div>
              <!--
                  FREF-REFERE-4 :
                  A button that toggles the display of referent's fiche.
               -->
              <Button
                icon="pi pi-file"
                label="Afficher/Cacher la fiche de mon référent"
                title="Afficher/Cacher la fiche de mon référent"
                @click="toggle_fiche_ref = !toggle_fiche_ref"
                style="margin-top: 5px; color: #fff; background: #ef7d00"
              />
              <br />
              <br />
            </div>
            <div class="ql-container ql-snow" v-if="toggle_fiche_ref">
              <div class="ql-editor">
                <!--
                    FREF-REFERE-4 : 
                    Displays the fiche on the UI if toggle_fiche_ref
                    is trus.
                -->
                <p v-html="referent['fiche_ref']" />
              </div>
            </div>
          </div>
        </div>
        <div class="column is-8">
          <!-- 
                FREF-
                Column where the student can find the informations of
                past and futur rendez-vous 
            -->
          <h1 class="title" style="cursor:pointer;"
                      @click="
                        download_file('Tuto_fiche_rdv_ref_elv.pdf')
                      ">Rendez-vous (<i
                      class="pi pi-question-circle"
                      
                    /> Tutoriel)</h1>

          <AppointmentsList
            :person_id_of_the_student="this.$store.getters.user['id']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppointmentsList from "../components/AppointmentsList.vue";
import axios from "axios";

export default {
  components: { AppointmentsList },
  data() {
    return { referent: null, toggle_fiche_ref: false ,publicPath: process.env.BASE_URL};
  },

  async created() {
    await this.get_referent();
  },

  methods: {
    get_referent() {
      /*
        FREF-REFERE-2 :
        Method that calls the API to get the user's referent.
      */
      axios
        .get(process.env.BASE_URL + "api/annuaire/referents/get/")
        .then((response) => (this.referent = response.data));
    },

    send_mail(referent) {
      /*
        FREF-REFERE-3 : 
        Method that add send a mail to the student's referent.
      */
      window.location.href = `mailto:${referent["mail"]}`;
    },
    download_file(file) {
      var filename = file;
      axios({
        method: "get",
        url:
          process.env.BASE_URL +
          "api/appointments/appointment/serve-document/",
        headers: { file: file },
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      //window.location.href = "api/appointments/appointment/serve_appointment_document/" + id;
    },
  },
};
</script>