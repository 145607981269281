<template>
  <div style="display:inline-block">
    <Toast />

    <!--
      FREF-RDV-2 :
      Displays 2 buttons to :
      - choose a file in the computer.
      - send the file to the backend.
     -->
    <span>
      <FileUpload
        chooseLabel="Envoyer la fiche (.pdf)"
        mode="basic"
        :multiple="false"
        :customUpload="true"
        :auto="true"
        @uploader="send_file"
        fileLimit="1"
        :showCancelButton="false"
        accept=".pdf"
        invalidFileTypeMessage="{0}: Type de fichier invalide, seul(s) type(s) accepté(s) : {1}"
      />
    </span>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadFile",
  props: ["appointment_id"],

  data() {
    return { file: "", current_user: null };
  },

  methods: {
    send_file(event) {
      /*
      FREF-RDV-2 :
      Method that send the file, the id of the current user,
      the id of the appointment and the id of his referent
      to the backend.
      */
      let formData = new FormData();
      this.current_user = this.$store.getters.user;
      formData.append("student", this.current_user["student"]);
      formData.append("referent", this.current_user["referent"]);
      formData.append("appointment_reference", this.appointment_id);
      formData.append("fiche_deposee_par_eleve", event.files[0]);
      axios
        .post(
          process.env.BASE_URL +
            "api/appointments/appointment/post-appointment/",
          formData,
          {
            headers: {
              "X-CSRFToken": this.$store.getters.csrf_token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(
          this.$toast.add({
            severity: "success",
            summary: "Nouvelle fiche enregistrée, un email a été envoyé à votre référent.",
            life: 2000,
          }),
          setTimeout(() => {  this.$emit("reload_appointments_done_by_user"); }, 2000)
        );
    },
  },
};
</script>