<template>
  <div class="home" v-if="this.$store.getters.user">
    <ConfirmDialog></ConfirmDialog>
    <h1 class="title">Mes Référés</h1>

    <hr />
    <div class="columns is-centered">
      <!--
        FREF-REFERENT-12 :
        Scrollable item which displays all the rendez-vous of the
        current year. Each item is clickable to download the template
        of the appointment file.
       -->
      <div class="column is-12 scrollable">
        <div class="level">
          <span
            class="appointment level-item"
            v-for="appointment in list_appointment_reference"
            :key="appointment.id"
          >
            <span class="box">
              <p class="appointment_title">
                <a
                  v-if="
                    is_RDV_ongoing(
                      appointment['date_fin'],
                      appointment['date_debut']
                    ) == 'true'
                  "
                  :href="appointment['url_fiche']"
                  style="color: orange; font-weight: bold"
                  target="_blank"
                >
                  <em class="pi pi-file" style="margin-right: 5px" />
                  <span class="appointment_title"
                    >RDV {{ appointment["niveau_acad"] }} -
                    {{ appointment["titre_rendez_vous"] }}
                  </span>
                </a>
                <a v-else :href="appointment['url_fiche']" target="_blank">
                  <em class="pi pi-file" style="margin-right: 5px" />
                  <span class="appointment_title"
                    >RDV {{ appointment["niveau_acad"] }} -
                    {{ appointment["titre_rendez_vous"] }}
                  </span>
                </a>
              </p>
              <small class="p-text-secondary"
                >{{ appointment["date_debut"] }}
                <i class="pi pi-arrow-right" />
                {{ appointment["date_fin"] }}</small
              >
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <div class="columns is-centered">
        <div class="column is-8">
          <div id="referes_datatable" v-if="referes">
            <!-- 
            FREF-REFERENT-3 : 
            Display a Datatable of the referes' information collected by the 
            get_referes() method.
          -->
            <ReferesDatatable :columns="columns" :referes="referes" />
          </div>
        </div>

        <!-- Espace pour l'affichage des fiches à valider et pour le calendrier -->
        <div
          class="column is-4"
          style="overflow-wrap: break-word"
          id="temp_rendez-vous"
        >
          <ValidateAppointment
            :person_id_of_the_referent="this.$store.getters.user['id']"
            :list_appointment_not_validated="
              this.list_not_validated_appointments
            "
            :key="ValidateAppointmentKey"
            @get_referes="get_referes"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReferesDatatable from "../components/ReferesDatatable.vue";
import AppointmentsList from "../components/AppointmentsList.vue";
import ValidateAppointment from "../components/ValidateAppointment.vue";
import axios from "axios";
import moment from "moment";

export default {
  name: "MesReferes",
  components: { ReferesDatatable, AppointmentsList, ValidateAppointment },

  data() {
    return {
      /*
      FREF-REFERENT-3 :
      "columns" is the list of the informations that are in the Datatable.
      "student" is the list of all informations collected in the DB
       FREF-REFERENT-4 :
       The option sortable in each columns defines which column can be sorted. 
       */
      columns: [
        {
          field: "id",
          header: "Id",
          excludeGlobalFilter: "true",
          hidden: "true",
          exportable: false,
        },
        {
          field: "person.civilite",
          header: "Civ.",
          excludeGlobalFilter: "true",
        },
        { field: "person.nom", header: "Nom", sortable: "true" },
        { field: "person.prenom", header: "Prénom", sortable: "true" },
        { field: "niveau_acad", header: "An.", sortable: "true" },
        { field: "statut_acad", header: "Sta.", sortable: "true" },

        // {
        //   field: "id_fiche_synapses",
        //   header: "Synapses",
        //   excludeGlobalFilter: "true",
        // },
      ],
      referes: null,
      list_appointment_reference: null,
      list_not_validated_appointments: [],
    };
  },

  async created() {
    await this.get_referes();
    await this.get_appointment_reference();
  },

  methods: {
    get_referes() {
      /* FREF-REFERENT-2 :
        Calls the API endpoint that returns the informations of the user's referes. The returned
        attributes are : "id", "civilite", "photo" "prenom", "nom", "mail", "referent", "niveau_acad",
        "statut_acad" and "id_fiche_synapses".
      */
      axios({
        method: "get",
        url: process.env.BASE_URL + "api/annuaire/students/",
        headers: { source: "mes_referes" },
      }).then((response) => {
        if (response.data.length != 0) {
          this.referes = response.data;
          this.get_appointments_not_validated();
        }
      });
    },

    get_appointments_not_validated() {
      this.list_not_validated_appointments = [];
      var appointment = null;
      for (var student of this.referes) {
        for (var appointment_from_db of student.appointments) {
          if (
            appointment_from_db.fiche_deposee_par_eleve != null &&
            appointment_from_db.statut_fiche == "not_validated"
          ) {
            appointment = appointment_from_db;
            appointment["student"] = student.person;
            this.list_not_validated_appointments.push(appointment);
          }
        }
      }
    },

    change_date_format() {
      for (var appointment of this.list_appointment_reference) {
        appointment["date_debut"] = this.reformat_date(
          appointment["date_debut"]
        );
        appointment["date_fin"] = this.reformat_date(appointment["date_fin"]);
      }
    },

    reformat_date(date_string) {
      var date_array = date_string.split("-");
      return date_array[2] + "/" + date_array[1] + "/" + date_array[0];
    },

    is_RDV_ongoing(end_date, start_date) {
      if (
        moment(start_date, "DD/MM/YYYY").toDate() < moment().toDate() &&
        moment().toDate() < moment(end_date, "DD/MM/YYYY").toDate()
      ) {
        return "true";
      } else {
        return "false";
      }
    },

    get_appointment_reference() {
      /* FREF-REFERENT-2 :
        Calls the API endpoint that returns the informations of the user's referes. The returned
        attributes are : "id", "civilite", "photo" "prenom", "nom", "mail", "referent", "niveau_acad",
        "statut_acad" and "id_fiche_synapses".
      */
      axios({
        method: "get",
        url:
          process.env.BASE_URL +
          "api/appointments/appointment/get-appointment-references/",
        headers: { source: "mes_referes" },
      }).then((response) => {
        if (response.data.length != 0) {
          this.list_appointment_reference = response.data;
          this.change_date_format();
        }
      });
    },
  },
};
</script>