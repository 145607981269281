<template>
  <div>
    <!-- Toast is the pop-up that appears when the change is saved -->
    <Toast />
    <!-- ANN-LISTE_ELV-7 :
    The following is only visible if the student has a referent.
    -->
    <div v-if="student_data['referent'] != null">
      <div id="delete_ref">
        <Button
          icon="pi pi-trash"
          title="Supprimer le référent"
          v-on:click="toggle(student_data['person_id'])"
          class="p-button-no-background"
        />
        <!-- Displays the name of the student's referent -->
        <span id="ref_name"
          >{{ student_data.referent.person.nom }}
          {{ student_data.referent.person.prenom }}</span
        >
      </div>
      <!-- ANN-LISTE_ELV-7 :
        Pop Up that opens when the icon is clicked. The admin can:
         - confirm the choice which send a request to Django
         - Click outside the pop up which closes it without doing anything
         - click the cancel button which closes it without doing anything
        -->
      <OverlayPanel
        ref="overlay_del_ref"
        :showCloseIcon="false"
        bodyStyle="text-align: center"
      >
        <div id="conf_del_ref">
          Voulez-vous supprimer le lien entre cet élève et ce référent ?
        </div>
        <div id="flex-jc-between">
          <Button
            label="Confirmer"
            class="p-button-success"
            @click="delete_ref(student_data.person.id)"
          />
          <Button
            label="Annuler"
            class="p-button-danger"
            @click="toggle(student_data.person.id)"
          />
        </div>
      </OverlayPanel>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["student_data"],

  data() {
    return { student_id: null };
  },

  methods: {
    delete_ref(std_id) {
      /* Method used when the admin confirm the choise to delete a student's referent.
      Sends a request to django with the selected student's id then closes the overlay */
      this.$refs.overlay_del_ref.toggle(event);
      var ref_id = null;
      axios
        .patch(
          process.env.BASE_URL + "api/annuaire/students/patch-ref/",
          {
            std_id: std_id,
            ref_id: ref_id,
          },
          {
            headers: {
              "X-CSRFToken": this.$store.getters.csrf_token,
            },
          }
        )
        .then(() => {
          this.$emit("get_students");
          this.$toast.add({
            severity: "success",
            summary: "Modifications enregistrées.",
            life: 3000,
          });
        });
    },

    toggle(std_id) {
      /* Method that toggles the pop up to confirm the deletion of a student's referent. */
      this.$refs.overlay_del_ref.toggle(event);
      if (std_id != this.student_id) {
        this.student_id = std_id;
      }
    },
  },
};
</script>

<style lang="scss">
.btn_confirm {
  align-content: left;
  margin: 10px;
}

.btn_cancel {
  align-content: right;
  margin: 10px;
}

.btn_del {
  margin-top: 3px;
  align-content: center;
}
</style>