<template>
  <div>
    <!-- Toast is the pop-up that appears when the change is saved -->
    <Toast />

    <!-- ANN-LISTE_REF-7 :
        Displays an eye icon if the referent is visible to other user 
        (accepte_eleve == true) else displays an eye with a slash in it.
        When the icon is clicked, opens an overlay to confirm the choice
        (prevents misclicks).
    -->
    <div v-if="data['accepte_eleve'] == true">
      <Button
        icon="pi pi-eye"
        title="Masquer le référent"
        v-on:click="toggle(data['referent_id'], true)"
        class="p-button-no-background"
      />
    </div>
    <div v-else>
      <Button
        icon="pi pi-eye-slash"
        title="Afficher le référent"
        v-on:click="toggle(data['referent_id'], false)"
        class="p-button-no-background"
      />
    </div>
    <!-- ANN-LISTE_REF-7 :
    Overlay with a simple text and 2 buttons. The admin can :
     - Confirm the choice, sending a request to Django
     - Click outside the pop-up to close it
     - Cancel to clo the pop up 
     -->
    <OverlayPanel
      ref="overlay_hide"
      :showCloseIcon="false"
      bodyStyle="text-align: center"
    >
      <div id="conf_hide_ref">{{ overlay_text }}</div>
      <div id="flex-jc-between">
        <Button
          label="Confirmer"
          class="p-button-success"
          @click="toggle_hide_ref(referent_id)"
        />
        <Button
          label="Annuler"
          class="p-button-danger"
          @click="toggle(referent_id)"
        />
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["data"],

  data() {
    return { referent_id: null, overlay_text: null };
  },

  methods: {
    toggle(ref_id, accepte_eleve) {
      /* 
      ANN-LISTE_REF-7 :
      Method that toggles the confirmation pop up. Also changes the 
      text that is displayed in the overlay.
      */
      this.$refs.overlay_hide.toggle(event);
      if (ref_id != this.referent_id) {
        this.referent_id = ref_id;
      }
      /* Text to display in the overlay */
      if (accepte_eleve) {
        this.overlay_text = "Voulez-vous masquer ce référent de l'annuaire ?";
      } else {
        this.overlay_text =
          "Voulez-vous afficher ce référent dans l'annuaire ?";
      }
    },

    toggle_hide_ref(ref_id, accepte_eleve) {
      /*
        ANN-LISTE_REF-7 :
        Method that send the request to change the attribute "accepte_eleve" 
        of the selected referent.
        */
      this.toggle(ref_id, accepte_eleve);
      axios
        .patch(
          process.env.BASE_URL + "api/annuaire/referents/toggle-hide/",
          { ref_id: this.referent_id },
          {
            headers: {
              "X-CSRFToken": this.$store.getters.csrf_token,
            },
          }
        )
        .then(
          this.$emit("get_referents"),
          this.$toast.add({
            severity: "success",
            summary: "Modifications enregistrées.",
            life: 3000,
          })
        );
    },
  },
};
</script>