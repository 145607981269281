<template>
  <div>
    <Datatable
      :value="referes"
      ref="referes_datatable"
      sortField="nom"
      sortOrder="1"
      :stripedRows="true"
      v-model:expandedRows="expandedRows"
      @rowExpand="onRowExpand"
      @rowCollapse="onRowCollapse"
      dataKey="person.id"
      v-model:filters="filters"
      :globalFilterFields="['nom', 'prenom', 'statut_acad', 'niveau_acad']"
      v-model:selection="selected_referes"
      responsiveLayout="stack"
      class="p-datatable-sm"
      breakpoint="960px"
    >
      <template #header>
        <span id="flex-jc-between">
          <!-- 
            FREF-REFERENT-5 :
            Display an input zone for the user to write in what they are looking for.
            The text written here will be searched in all columns that are in the 
            Datatable's globalFilterFields option.
            -->
          <div class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="Rechercher"
            />
          </div>

          <span style="text-align: left">
            <!-- 
            FREF-REFERENT-7 :
            Diplay a button that calls the method to send a mail to every selected students
          -->
            <Button
              icon="pi pi-envelope"
              title="Envoyer un mail"
              @click="send_mail_to_list(selected_referes)"
              style="margin-right: 5px"
            />
          </span>
        </span>
      </template>

      <!-- 
        FREF-REFERENT-7 : 
        Puts checkboxes in every row so that the user can select the students he wants
        to send a mail to.
      -->
      <Column selectionMode="multiple" headerStyle="width: 3em " />

      <Column header="Photo" field="photo">
        <template #body="std_photo">
          <div v-if="std_photo.data.person['droit_image']">
            <img
              :src="
                './static/img/' +
                std_photo.data.person['photo'] +
                '.jpg'
              "
              alt="photo référé"
              width="45"
              height="45"
            />
          </div>
          <div v-else>
            <em class="pi pi-user" style="font-size: 3.5rem" />
          </div>
        </template>
      </Column>
      <Column
        v-for="col of columns"
        :field="col.field"
        :header="col.header"
        :sortable="col.sortable"
        :hidden="col.hidden"
        :key="col.field"
      ></Column>

      <!-- 
        FREF-REFERENT-6 :
        Column with a mail icon. If the icon is clicked, the user's webmail
        opens with the receiver of the message already set.
      -->
      <Column
        field="id_fiche_synapses"
        header="Synapses"
        excludeGlobalFilter="true"
      >
        <template #body="id_fiche">
          <Button
            icon="pi pi-external-link"
            title="Fiche Synapses"
            v-on:click="open_synapses(id_fiche.data['id_fiche_synapses'])"
            class="p-button-no-background"
          />
        </template>
        <template #editor="id_fiche">
          <Button
            icon="pi pi-external-link"
            title="Fiche Synapses"
            v-on:click="open_synapses(id_fiche.data['id_fiche_synapses'])"
            class="p-button-no-background"
          />
        </template>
      </Column>
      <Column header="Comment. ref" excludeGlobalFilter="true">
        <template #body="student">
          <!-- 
            FREF-REFERENT-14 :
            Displays a button that can be clicked to access the referent's
            private comment on one specific student.
          -->
          <Button icon="pi pi-comment" label="" @click="toggle_comment_ref(student)" />
          <Dialog
            class="p-dialog"
            v-model:visible="display_comment_ref_dialog"
            header="Commentaire privé du référent"
            :modal="true"
            :draggable="false"
          >
            <div style="display: block">
              <div>
                <i class="p-dialog-icon pi pi-exclamation-triangle" />
                <span class="p-dialog-message">
                  Commentaire privé du référent sur l'élève
                </span>
              </div>
              <Textarea
                name="commentaire_referent"
                v-model="current_student.data['comment_ref']"
                cols="70"
                rows="13"
                placeholder="Commentaire visible uniquement du référent"
                :required="true"
                style="resize: none"
              />
            </div>
            <template #footer>
              <Button
                label="Annuler"
                @click="toggle_comment_ref"
                class="p-button p-confirm-dialog-reject"
              />
              <Button
                label="Enregistrer"
                @click="
                  update_comment_ref(
                    current_student.data.person['id'],
                    current_student.data['comment_ref']
                  )
                "
                class="p-button p-confirm-dialog"
                autofocus=""
              />
            </template>
          </Dialog>
        </template>
      </Column>
      <Column header="Mail" excludeGlobalFilter="true">
        <template #body="mail_body">
          <Button
            icon="pi pi-envelope"
            title="Envoyer un mail"
            v-on:click="send_mail_to_one([mail_body.data.person])"
            class="p-button-no-background"
          />
        </template>
      </Column>
      <!-- 
        FREF-REFERENT-13 :
        Displays an expander button. If clicked, the list of all appointments
        done by the student is displayed on screen, same as the view
        MonReferent.
      -->
      <Column :expander="true" headerStyle="width: 3em" />
      <template #expansion="student">
        <AppointmentsList
          :person_id_of_the_student="student.data.person['id']"
          :current_page="'mes_referes'"
        />
      </template>
    </Datatable>
  </div>
</template>

<script>
import axios from "axios";

import { FilterMatchMode } from "primevue/api";
import AppointmentsList from "../components/AppointmentsList.vue";

export default {
  props: ["referes", "columns"],
  components: { AppointmentsList },

  data() {
    return {
      /*
      FREF-REFERENT-5 : 
      "filters" represents how the search engine will work. 'global' represents
      the search bar.
      */
      filters: { global: { value: null, matchMode: FilterMatchMode.CONTAINS } },
      /*
      FREF-REFERENT-7 :
      List of all of the selected rows
      */
      selected_referes: [],
      expandedRows: [],
      display_comment_ref_dialog:false,
      current_student:null,

    };
  },

  methods: {
    send_mail_to_one(person) {
      /*
        ANN-LISTE_ELV-11 : 
        Method that add send a mail to every person in the
        var list_person.
      */
      var mail_receiver = "";
      for (var ind_person in person) {
        mail_receiver += person[ind_person]["mail"];
        mail_receiver += ",";
      }
      if (mail_receiver != "") {
        window.location.href = `mailto:?bcc=${mail_receiver}`;
      }
    },

    send_mail_to_list(list_person) {
      /*
        ANN-LISTE_ELV-11 : 
        Method that add send a mail to every person in the
        var list_person.
      */
      var mail_receiver = "";
      for (var i = 0; i < list_person.length; i++) {
        var student = list_person[i];
        mail_receiver += student.person.mail;
        mail_receiver += ",";
      }
      if (mail_receiver != "") {
        window.location.href = `mailto:?bcc=${mail_receiver}`;
      }
    },

    open_synapses(id_fiche) {
      window.open(
        `https://synapses.mines-albi.fr/dossier-pedagogique/${id_fiche}`,
        "_blank"
      );
    },

    update_comment_ref(id_student, commentaire_referent) {
      /*
      FREF-REFERENT-14 :
      Method to update the referent's private comment on one of his
      refere. Send a request to "~~/api/annuaire/students/update-comment-ref/"
      with the new comment in the data.
       */
      if (commentaire_referent) {
        axios
          .patch(
            process.env.BASE_URL + "api/annuaire/students/update-comment-ref/",
            { commentaire_referent: commentaire_referent, student: id_student },
            {
              headers: {
                "X-CSRFToken": this.$store.getters.csrf_token,
              },
            }
          )
          .then((response) => {
            this.toggle_comment_ref();
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Ajouter un commentaire privé sur l'élève",
          life: 3000,
        });
      }
    },

    toggle_comment_ref(student=null) {
      this.current_student=student;
      this.display_comment_ref_dialog = !this.display_comment_ref_dialog;
    },
  },
};
</script>