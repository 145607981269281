<template>
  <div class="home" v-if="this.$store.getters.user">
    <h1 class="title">Annuaire des référents</h1>

    <hr />

    <div class="columns is-centered">
      <div class="column is-11">
        <div id="ref">
          <!-- ANN-LISTE_REF-3 :
          Display a Datatable of all referents with the informations 'civilite', 'photo'
          'nom', 'prenom', 'service', the number of referes, a button that redirects to thunderbird
          and a button to show one referent's fiche -->
          <ReferentDatatable
            :data="referents"
            :columns="columns"
            :fiche_referent="fiche_referent"
            :loading="loading"
            @get_referents="get_referents"
          ></ReferentDatatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ReferentDatatable from "../components/ReferentDatatable.vue";

export default {
  name: "Annuaire",
  components: {
    ReferentDatatable,
  },

  data() {
    return {
      /* Columns are the columns we want to display on the datatable with their options */
      columns: [
        {
          field: "id",
          header: "Id",
          excludeGlobalFilter: "true",
          hidden: "true",
          exportable: false,
        },
        {
          field: "referent_id",
          header: "Referent_id",
          excludeGlobalFilter: "true",
          hidden: "true",
          exportable: false,
        },
        { field: "civilite", header: "Civilité", excludeGlobalFilter: "true" },
        { field: "nom", header: "Nom", sortable: "true" },
        { field: "prenom", header: "Prénom", sortable: "true" },
        {
          field: "service",
          header: "Service",
          sortable: "true",
          style: "width: 15px",
        },
        { field: "nb_student", header: "Référés", sortable: "true" },
      ],
      /* List of all referents with the infos we want to show */
      referents: null,
      /* We only stock one fiche at a times */
      fiche_referent: null,
      loading: false,
    };
  },

  async created() {
    this.loading = true;
    await this.get_referents();
  },

  methods: {
    get_referents() {
      /* ANN-LISTE_REF-2 :
        Method that get the attributes 'id', 'photo' 'civilite', 'nom', 'prenom', 'mail', 
        'referent_id', 'service', 'student' and 'accepte_eleve' of all referents in the
        database from the API endpoint "~~/api/annuaire/referents/".
        'student' is the number of referes.
        */
      axios({
        method: "get",
        url: process.env.BASE_URL + "api/annuaire/referents/",
      }).then(
        (response) => ((this.referents = response.data), (this.loading = false))
      );
    },

    select_fiche(id_referent) {
      /* ANN-FICHE_REF-2 :
      Method that change the value of the variable id_referent_select into id_referent.
      This is used to select the card of the referent the user wants to see.
      input : the id of a referent ('id_referent').
      output :  if id_referent_select === idRef, the card is unselected to hide it in the UI.
        id_referent_select => null
                else id_referent_select become id_referent.
        id_referent_select => id_referent
      */
      if (this.id_referent_select === id_referent) {
        this.id_referent_select = null;
      } else {
        this.id_referent_select = id_referent;
      }
      // Call to the method that retrieve the referent's card.
      this.get_fiche_referent(id_referent);
    },

    async get_fiche_referent(referent_id) {
      /* ANN-FICHE_REF-2 
      Method that retrieve one referent's card
      input : the id of a referent
      output :  if no referent is selected, the card stored is not changed
                else fiche_referent becomes the card of the referents identified by referent_id
      */
      this.fiche_referent = null;
      await axios({
        method: "get",
        url:
          process.env.BASE_URL +
          "api/annuaire/referents/" +
          referent_id +
          "/fiche-ref/",
      }).then((response) => (this.fiche_referent = response.data));
    },
  },
};
</script>

<style lang="scss">
.ref {
  text-align: center;
}
</style>
