import { createRouter, createWebHistory } from 'vue-router'
import ReferentAnnuaire from '../views/ReferentAnnuaire.vue'
import MesReferes from '../views/MesReferes.vue'
import Home from '../views/Home.vue'
import StudentAnnuaire from '../views/StudentAnnuaire.vue'
import EditFicheRef from '../views/EditFicheRef.vue'
import MonReferent from '../views/MonReferent.vue'
import store from '../store/store'


const routes = [
  {
    /* ANN-LISTE_REF-1 :
      Annuaire link in the router.
    */
    path: '/annuaire',
    name: 'ReferentAnnuaire',
    component: ReferentAnnuaire
  },
  {
    /* ANN-LISTE_ELV-1 :
      Router link to the student's dictionnary. The beforeEnter function is a Navigation Guard
      that checks whether the user is an admin, if he is not he is redirected to the Home page.
    */
    path: '/annuaire/student',
    name: 'StudentAnnuaire',
    component: StudentAnnuaire,
    beforeEnter: (to, from) => {
      if (!store.getters.user['is_admin']) {
        router.push({ name: 'Home' })
      }
      else {
        return true
      }
    },
  },
  {
    /* FREF-REFERENT-1 :
      Router link to the list of the user's referes. Only available if the user is a referent.
      If the user is not a referent but tries to access the page, he is redirected to the
      Home page.
    */
    path: '/mes-referes',
    name: 'MesReferes',
    component: MesReferes,
    beforeEnter: () => {
      if (!store.getters.user['is_referent']) {
        router.push({ name: 'Home' })
      }
      else {
        return true
      }
    }
  },
  {
    /* ANN-FICHE_REF-4 :
      Router link to a text editor where the user can change their fiche ref. Only available if
      the user is a referent. If the user is not a referent but tries to access the page, he is
      redirected to the Home page.
    */
    path: '/fiche',
    name: 'EditFicheRef',
    component: EditFicheRef,
    beforeEnter: () => {
      if (!store.getters.user['is_referent']) {
        router.push({ name: 'Home' })
      }
      else {
        return true
      }
    }
  },
  {
    /*
      FREF-REFERE-1 :
      Router link to the page where the user's referent and the rendez-vous are displayed.
      This page is only accessible if the user is a student and has a referent, else he is
      redirected to the Home page.
    */
    path: '/mon-referent',
    name: 'MonReferent',
    component: MonReferent,
    beforeEnter: (to, from) => {
      if (!store.getters.user['is_student'] || !store.getters.user['referent']) {
        router.push({ name: 'Home' })
      }
      else {
        return true
      }
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  }
]


const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
