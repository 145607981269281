<template>
  <div class="home" v-if="current_user">
    <div class="content" style="text-align:left;padding-left:20px">
    <h1>Bienvenue sur l'application Référents/Référés</h1>
    <h3>Présentation de la fonction référent</h3>
    <p>Avant de commencer le processus de choix de votre référent, merci de prendre connaissance de la présentation de la fonction et de ces dates clés : <a href="https://campus-rfi.mines-albi.fr/course/view.php?id=141" target="_blank">Lien Campus</a>
      <br>
      <h3>Comment choisir son référent ?</h3>
      <ol>
        <li> Envoyer un mail au réferent de votre choix pour vous présenter et lui demander de vous accompagner à partir de l'annuaire des référents<br>
        <i>Conseil : pour mettre toutes les chances de votre coté, mettez en avant vos motivations et argumentez votre choix</i></li>
        <li>Le référent a la possibilité :
            <ul>
              <li>de vous demander de vous rencontrer pour échanger avec vous, et  vous donner sa réponse par la suite</li>
              <li>de vous répondre directement par mail</li>
            </ul>
            Si la personne que vous avez contactée ne vous répond pas au-delà d’une semaine, vous pouvez contacter une autre personne.
        </li>
        <li>Une fois votre réponse positive reçue, envoyez un mail pour communiquer le nom de votre référent à l’adresse :  <a href="mailto:referents-admin@listes.mines-albi.fr">referents-admin@listes.mines-albi.fr</a></li>
      </ol>
    Pour toute question ou accompagnement dans le processus de choix de referent, contactez :  <a href="mailto:referents-admin@listes.mines-albi.fr">referents-admin@listes.mines-albi.fr</a>
    <br>
      <h3>Calendrier des rendez-vous obligatoires et fiches pour les rendez-vous </h3>
    Nous vous invitons à prendre connaissance du calendrier des rendez-vous obligatoires avec votre référent et à télécharger votre <a href="https://campus-rfi.mines-albi.fr/course/view.php?id=141" target="_blank">fiche RDV disponible sur Campus</a>
    <br/>
    Vous devez:
    <ul>
      <li>Initier le rendez-vous avec votre référent (s'y prendre suffisamment tôt pour respecter les périodes des RDV obligatoires et les disponibilités de votre référent)</li>
      <li>Avoir préparé le travail demandé pour les RDV obligatoires et venir avec les documents prévus et déjà pré-renseignés pour ce rendez-vous</li>
    </ul>
    </p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    current_user() {
      return this.$store.getters.user;
    },
  },
};
</script>

