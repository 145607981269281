<template>
  <div class="home" v-if="this.$store.getters.user">
    <h1 class="title">Annuaire des élèves</h1>

    <hr />

    <div class="columns is-centered">
      <div class="column is-11">
        <!--
            ANN-LISTE_ELV-3 :
            Display a Datatable of all student's information collected by the 
            get_students() method.
           -->
        <StudentDatatable
          :students="students"
          :referents="referents"
          :columns="columns"
          :referents_names="referents_names"
          :loading="loading"
          :list_appointment_number="list_appointment_number"
          :filters="filters"
          @get_students="get_students"
          @change_loading_state="change_loading_state"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StudentDatatable from "../components/StudentDatatable.vue";
import axios from "axios";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "StudentAnnuaire",
  components: { StudentDatatable },

  data() {
    return {
      /*
      ANN-LISTE_ELV-3 :
      "columns" is the list of the informations that are in the Datatable.
      "student" is the list of all informations collected in the DB
       ANN-LISTE_ELV-4 :
       The option sortable in each columns defines which column can be sorted. 
       */
      columns: [
        {
          field: "id",
          header: "Id",
          excludeGlobalFilter: "true",
          hidden: "true",
          exportable: false,
        },
        {
          field: "person.civilite",
          header: "Civilité",
          excludeGlobalFilter: "true",
        },
        { field: "person.nom", header: "Nom", sortable: "true" },
        { field: "person.prenom", header: "Prénom", sortable: "true" },
        { field: "statut_acad", header: "Statut", sortable: "true" },
      ],
      /*
      ANN-LISTE_ELV-5 : 
      "filters" represents how the search engine will work. 'global' is for
      the search bar and 'referent' is for the column "Référent".
      */
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "referent.person.id": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        niveau_acad: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },

      students: null,
      /*
      ANN-LISTE_ELV-6 :
      Variables needed to edit a student's referent : 
      - referents is the list of referents given by the API
      - referents_names is the list of the referents displayed in the search dropdown menu
      */
      referents_names: [],
      referents: null,
      loading: false,
      list_appointment_number: [],
    };
  },

  async mounted() {
    /* Load the informations that we need to display in the Datatable */
    this.loading = true;
    await Promise.all([this.get_students(), this.get_referent()]);
  },

  methods: {
    get_students() {
      /* ANN-LISTE_ELV-2 :
        Calls the API endpoint that returns the informations of all students. The returned attributes
        are : "id", "civilite", "photo", "prenom", "nom", "mail", "referent", "niveau_acad",
        "statut_acad" and "id_fiche_synapses".
        */
      axios({
        method: "get",
        url: process.env.BASE_URL + "api/annuaire/students/",
        headers: { source: "students_annuaire" },
      }).then((response) => {
        this.students = response.data;
        this.loading = false;
        this.set_appointment_number(response.data);
        this.set_datatable_filters();
        this.flatten_students_dict();
      });
    },

    get_referent() {
      /* ANN-LISTE_ELV-6 :
        Retrieve the list of referents is displayed in the dropdown menu.
        */
      axios({
        method: "get",
        url:
          process.env.BASE_URL + "api/annuaire/referents/referent/list-select/",
      }).then((response) => {
        this.referents = response.data;
        this.set_referent_names();
      });
    },

    set_referent_names() {
      /* ANN-LISTE_ELV-8 : 
        Creates the choices for the referents' filter. The last push represent students'
        with no referent, if the value of the string is changed here, it must also be
        changed in module referent/annuaire/views.py + class StudentViewSet + function list 
        */
      var referent_name = null;
      var referent = null;
      for (var ind_referent in this.referents) {
        referent_name =
          this.referents[ind_referent].person["nom"] +
          " " +
          this.referents[ind_referent].person["prenom"];
        referent = {
          label: referent_name,
          value: this.referents[ind_referent].person_id,
        };
        this.referents_names.push(referent);
      }
      this.referents_names.push({ label: "N/D", value: null });
    },

    change_loading_state(new_value) {
      this.loading = new_value;
    },

    set_appointment_number(list_student) {
      /*
        Set the variable appointment_number used in creating enough
        filters and columns in the datatable.
      */
      var _max_number_of_appointment = 0;
      for (var student_number in list_student) {
        if (
          _max_number_of_appointment <
          list_student[student_number]["appointments"].length
        ) {
          _max_number_of_appointment =
            list_student[student_number]["appointments"].length;
        }
      }
      this.list_appointment_number = Array.from(
        Array(_max_number_of_appointment).keys()
      );
    },

    set_datatable_filters() {
      /*
        Create one filter for each appointment number. Used in the datatable to
        filter which student did their appointment.
      */
      for (var appointment_number of this.list_appointment_number) {
        this.filters["appointment_" + appointment_number + ".statut_fiche"] = {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        };
      }
    },
    flatten_students_dict() {
      /*
        For each student, get every appointment from the list appointments
        and put them directly in the dict "students", flattening the dictionary.
        Neccessary for the filtering to work in the datatable.
      */
      for (var student of this.students) {
        let appointment_number = 0;
        for (var appointment of student["appointments"]) {
          student[`appointment_${appointment_number}`] = appointment;
          appointment_number += 1;
        }
      }
    },
  },
};
</script>