<template>
  <div>
    <div v-for="niveau_acad in list_all_niveau_acad" :key="niveau_acad">
      <!-- Create a Panel for each different niveau_acad in the 
      appointments extracted from the local database 
      Appointments that can be made during any academic year are
      displayed at the bottom of the page, they 
      -->
      <article class="panel is-primary" style="margin-bottom: 0.5rem">
        <p class="panel-heading" v-if="niveau_acad == '0A'">
          Rendez-vous pendant la scolarité
        </p>
        <p class="panel-heading" v-else>Rendez-vous en {{ niveau_acad }}</p>
        <div id="flex-jc-between" style="align-items: stretch; padding: 5px">
          <span
            class="appointment"
            v-for="appointment in list_appointment_of_one_student"
            :key="appointment.id"
          >
            <!-- 
              Display in each panel only the appointment that are the same
              niveau_acad as the panel.
            -->
            <span
              v-if="
                appointment['appointment_reference']['niveau_acad'] ==
                niveau_acad
              "
            >
              <!-- 
                FREF-REFERE-7 :
                Display all appointment for which the student has submitted a file.
              -->
              <span v-if="appointment['fiche_deposee_par_eleve']">
                <span class="box">
                  <a
                    v-if="
                      is_RDV_ongoing(
                        appointment['appointment_reference']['date_fin'],
                        appointment['appointment_reference']['date_debut']
                      ) == 'true'
                    "
                    :href="appointment['appointment_reference']['url_fiche']"
                    style="color: orange; font-weight: bold"
                    target="_blank"
                  >
                    <em class="pi pi-file" style="margin-right: 5px" />
                    <span class="appointment_title">
                      {{
                        appointment["appointment_reference"][
                          "titre_rendez_vous"
                        ]
                      }}
                    </span>
                  </a>
                  <a
                    v-else
                    :href="appointment['appointment_reference']['url_fiche']"
                    target="_blank"
                  >
                    <em class="pi pi-file" style="margin-right: 5px" />
                    <span class="appointment_title">
                      {{
                        appointment["appointment_reference"][
                          "titre_rendez_vous"
                        ]
                      }}
                    </span>
                  </a>

                  <span style="text-align: center" class="block">
                    <ul>
                      <li>
                        A faire du
                        {{ appointment["appointment_reference"]["date_debut"] }}
                        au
                        {{ appointment["appointment_reference"]["date_fin"] }}
                      </li>
                      <li>
                        Fiche déposée le : {{ appointment["date_depot"] }}
                      </li>
                      <li>
                        État de la fiche :
                        <!-- 
                          FREF-REFERE-10 : 
                          Display a checkmark if the appoitment has been approved by the
                          student's referent.
                          -->
                        <div v-if="appointment['statut_fiche'] == 'not_exists'">
                          <i class="pi pi-check" style="color: rgb(255, 255, 255)" />
                          N'existe pas
                        </div>
                        <div v-else-if="appointment['statut_fiche'] == 'validated'">
                          <i class="pi pi-check" style="color: green" />
                          Validée par le référent
                        </div>
                        <div
                          v-else-if="
                            appointment['statut_fiche'] == 'not_validated'
                          "
                        >
                          <i class="pi pi-replay" style="color: #ef7d00" />
                          En cours de validation par le référent
                        </div>
                        <div
                          v-else-if="
                            appointment['statut_fiche'] == 'not_uploaded'
                          "
                        >
                          <i
                            v-if="
                              date_fiche_passed(
                                appointment['appointment_reference'][
                                  'date_fin'
                                ],
                                appointment['appointment_reference'][
                                  'date_debut'
                                ]
                              ) == 'inlate'
                            "
                            class="pi pi-exclamation-circle"
                            style="color: red"
                            title="Non validé par le référent, en retard!"
                          />
                          <i
                            v-if="
                              date_fiche_passed(
                                appointment['appointment_reference'][
                                  'date_fin'
                                ],
                                appointment['appointment_reference'][
                                  'date_debut'
                                ]
                              ) == 'todo'
                            "
                            class="pi pi-exclamation-circle"
                            style="color: #ef7d00"
                            title="Non validé par le référent"
                          />
                          <i
                            v-if="
                              date_fiche_passed(
                                appointment['appointment_reference'][
                                  'date_fin'
                                ],
                                appointment['appointment_reference'][
                                  'date_debut'
                                ]
                              ) == 'toosoon'
                            "
                            class="pi pi-exclamation-circle"
                            style="color: green"
                            title="Non validé par le référent, période non démarrée"
                          />
                          Non validée par le référent
                          <!-- 
                            FREF-REFERE-11 :
                            If the appointment is not validated and the referent left a comment,
                            display a button for the student to see his referent's comment.
                          -->
                          <span v-if="appointment['commentaire_referent']">
                            <Button
                              icon="pi pi-comment"
                              label=""
                              @click="toggle_comment(appointment)"
                            />
                          </span>
                        </div>
                      </li>
                    </ul>
                  </span>
                  <span class="block" style="display: inline-block">
                    <!-- 
                      FREF-REFERE-7 :
                      Display a button to download the appointment file.
                    -->
                    <Button
                      :key="appointment['fiche_deposee_par_eleve']"
                      icon="pi pi-download"
                      label="Consulter"
                      @click="
                        download_appointment_file(
                          appointment['id'],
                          appointment['fiche_deposee_par_eleve']
                        )
                      "
                    />
                  </span>
                  <span
                    class="block"
                    style="display: inline-block"
                    v-if="
                      appointment['statut_fiche'] != 'validated' &&
                      current_page != 'mes_referes'
                    "
                  >
                    <!-- 
                    FREF-REFERE-8 :
                    Button to change the file uploaded by the student.
                  -->
                    <UploadFile
                      :appointment_id="
                        appointment['appointment_reference']['id']
                      "
                      @reload_appointments_done_by_user="reload()"
                    />
                  </span>
                </span>
              </span>

              <!-- 
              FREF-REFERE-5 :
              Display all appointments the student will have to make during the year
              -->
              <span v-else>
                <span class="box">
                  <a
                    v-if="
                      is_RDV_ongoing(
                        appointment['appointment_reference']['date_fin'],
                        appointment['appointment_reference']['date_debut']
                      ) == 'true'
                    "
                    :href="appointment['appointment_reference']['url_fiche']"
                    style="color: orange; font-weight: bold"
                    target="_blank"
                  >
                    <em class="pi pi-file" style="margin-right: 5px" />
                    <span class="appointment_title">
                      {{
                        appointment["appointment_reference"][
                          "titre_rendez_vous"
                        ]
                      }}</span
                    >
                  </a>
                  <a
                    v-else
                    :href="appointment['appointment_reference']['url_fiche']"
                    target="_blank"
                  >
                    <em class="pi pi-file" style="margin-right: 5px" />
                    <span class="appointment_title">
                      {{
                        appointment["appointment_reference"][
                          "titre_rendez_vous"
                        ]
                      }}</span
                    >
                  </a>
                  <div>
                    Date de RDV :
                    {{ appointment["appointment_reference"]["date_debut"] }} -
                    {{ appointment["appointment_reference"]["date_fin"] }}
                  </div>
                  <span v-if="current_page != 'mes_referes'">
                    <!-- 
                        FREF-REFERE-7 :
                        Display a button that allow the student to upload a file.
                      -->
                    <UploadFile
                      :appointment_id="
                        appointment['appointment_reference']['id']
                      "
                      @reload_appointments_done_by_user="reload()"
                    />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
      </article>
    </div>
    <Dialog
      class="p-dialog"
      v-model:visible="display_comment"
      header="Commentaire du référent"
      :modal="true"
      :draggable="false"
    >
      <div class="comment_referent">
        {{ referent_comment }}
      </div>
    </Dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import UploadFile from "../components/UploadFile.vue";

export default {
  name: "AppointmentsList",
  components: { UploadFile },
  props: ["person_id_of_the_student", "current_page"],

  data() {
    return {
      list_all_niveau_acad: null,
      list_appointment_of_one_student: null,
      referent_comment: null,
      display_comment: false,
      maindiv: 0,
    };
  },

  created() {
    this.get_all_appointments_of_the_student();
  },

  methods: {
    get_all_appointments_of_the_student() {
      /*
      FREF-REFERE-5 :
      Method that returns all appointment file of the current user.
      Then calls the method to mark which appointment has already been
      done. 
      */
      axios({
        method: "get",
        url:
          process.env.BASE_URL +
          "api/appointments/appointment/get-list-appointment-of-one-student/",
        headers: { student: this.person_id_of_the_student },
      }).then((response) => {
        this.list_appointment_of_one_student = response.data;
        this.change_date_format();
        this.list_all_niveau_acad = this.list_appointment_of_one_student
          .map((item) => item.appointment_reference.niveau_acad)
          .filter((value, index, self) => self.indexOf(value) === index)
          .reverse();
      });
    },

    change_date_format() {
      for (var appointment of this.list_appointment_of_one_student) {
        appointment["date_depot"] = this.reformat_date(
          appointment["date_depot"]
        );
        appointment["appointment_reference"]["date_debut"] = this.reformat_date(
          appointment["appointment_reference"]["date_debut"]
        );
        appointment["appointment_reference"]["date_fin"] = this.reformat_date(
          appointment["appointment_reference"]["date_fin"]
        );
      }
    },

    reformat_date(date_string) {
      var date_array = date_string.split("-");
      return date_array[2] + "/" + date_array[1] + "/" + date_array[0];
    },

    download_appointment_file(id_appointment, file) {
      /*
      FREF-REFERE-6 :
      Method to download a blank appointment file.
       */
      var filename = file.split("/media/");
      axios({
        method: "get",
        url:
          process.env.BASE_URL +
          "api/appointments/appointment/serve-appointment-document/",
        headers: { appointment: id_appointment },
        responseType: "blob" // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename[1]); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      //window.location.href = "api/appointments/appointment/serve_appointment_document/" + id;
    },

    toggle_comment(appointment) {
      this.referent_comment = appointment["commentaire_referent"];
      this.display_comment = !this.display_comment;
    },

    is_RDV_ongoing(end_date, start_date) {
      if (
        moment(start_date, "DD/MM/YYYY").toDate() < moment().toDate() &&
        moment().toDate() < moment(end_date, "DD/MM/YYYY").toDate()
      ) {
        return "true";
      } else {
        return "false";
      }
    },

    date_fiche_passed(limit_date, start_date) {
      if (moment().toDate() < moment(start_date, "DD/MM/YYYY").toDate()) {
        return "toosoon";
      } else if (
        moment().toDate() < moment(limit_date, "DD/MM/YYYY").toDate()
      ) {
        return "todo";
      } else {
        return "inlate";
      }
    },
    reload() {
      window.location.reload();
    },
  },
};
</script>