<template>
  <div>
    <Datatable
      :value="data"
      ref="referent_datatable"
      sortField="nom"
      sortOrder="1"
      v-model:filters="filters"
      :globalFilterFields="['nom', 'prenom', 'service']"
      filterDisplay="menu"
      v-model:expandedRows="expandedRows"
      dataKey="person_id"
      @rowExpand="onRowExpand"
      @rowCollapse="onRowCollapse"
      :stripedRows="true"
      exportFilename="datatable_referent"
      csvSeparator=";"
      v-model:selection="selected_persons"
      responsiveLayout="stack"
      class="p-datatable-sm"
      breakpoint="960px"
      :loading="loading"
    >
      <template #header>
        <div id="flex-jc-between">
          <!-- 
            ANN-LISTE_REF-5 :
            Search bar to fasten the searching process. The input is searched in the 
            columns written in the option globalFilterFields. 
            -->
          <div class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="Rechercher"
            />
          </div>
          <span v-if="this.$store.getters.user['is_admin']">
            <!-- 
            ANN-LISTE_REF-9 :
            Diplay a button that calls the method to send a mail to every selected referents.
            -->
            <Button
              icon="pi pi-envelope"
              title="Envoyer un mail"
              @click="send_mail(selected_persons)"
              style="margin-right: 5px"
            />

            <!-- ANN-LISTE_REF-6 :
             Export button only accessible by admin. Clicking it will download
             the data currently displayed in the Datatable.
             -->
            <Button
              icon="pi pi-file-excel"
              title="Export"
              @click="exportCSV($event)"
            />
            <!--
              ANN-LISTE_REF-10 :
              Button that opens the form to add a new referent to Django's
              DB.
             -->
            <Button
              class="button-success"
              icon="pi pi-user-plus"
              title="Ajouter un référent"
              @click="open_add_referent()"
              style="margin-right: 5px"
            />

            <!--
              ANN-LISTE_REF-11 :
              Button that toggles the Pop up asking whether the user really
              wants to delete the selected referents.
             -->
            <Button
              class="button-danger"
              icon="pi pi-user-minus"
              title="Supprimer un référent"
              @click="open_del_referent()"
              style="margin-right: 5px"
            />
          </span>
        </div>
      </template>
      <!-- 
        ANN-LISTE_REF-9 : 
        Puts checkboxes in every row so that the admin can select the referents he wants
        to send a mail to.
      -->
      <Column
        selectionMode="multiple"
        headerStyle="width: 3em"
        v-if="this.$store.getters.user['is_admin']"
      />
      <Column header="Photo" field="photo" :exportable="false">
        <template #body="ref_photo">
          <div v-if="ref_photo.data['droit_image']">
            <img
              :src="
                './static/img/' +
                ref_photo.data['photo'] +
                '.jpg'
              "
              alt="photo référent"
              width="45"
              height="45"
            />
          </div>
          <div v-else>
            <em class="pi pi-user" style="font-size: 3.5rem" />
          </div>
        </template>
      </Column>
      <Column
        v-for="col of columns"
        :field="col.field"
        :header="col.header"
        :sortable="col.sortable"
        :excludeGlobalFilter="col.excludeGlobalFilter"
        :hidden="col.hidden"
        :key="col.field"
        :exportable="col.exportable"
        :style="col.style"
      ></Column>
      <!-- 
        ANN-LISTE_REF-8 :
        Column with a mail icon. If the icon is clicked, the user's webmail
        opens with the receiver of the message already set.
      -->
      <Column header="Contacter" excludeGlobalFilter="true">
        <template #body="mail_body">
          <Button
            icon="pi pi-envelope"
            title="Envoyer un mail"
            v-on:click="send_mail([mail_body.data])"
            class="p-button-no-background"
          />
        </template>
      </Column>

      <!-- 
        ANN-LISTE_REF-7
        Column with either an eye icon (if the referent is visible)
        of a slashed eye icon (if the referent is hidden) only displayed
        to an admin. If the icon is clicked, a confirmation pop-up opens
        with a confirmation button, if clicked the referent becomes 
        the referent's attribute "accepte_eleve" changes from true
        to false or reverse.
        Only referents with "accepte_eleve"==true are visible to 
        other users.
      -->
      <div v-if="this.$store.getters.user['is_admin']">
        <Column
          header="Visible"
          excludeGlobalFilter="true"
          bodyStyle="text-align: center; vertical-align: middle;"
        >
          <template #body="visible_body">
            <HideReferent
              :data="visible_body.data"
              @get_referents="get_referents"
            />
          </template>
        </Column>
      </div>
      <Column :expander="true" headerStyle="width: 3em" />
      <template #expansion>
        <div class="ql-container ql-snow" v-if="fiche_referent">
          <div class="ql-editor">
            <!--
            ANN-FICHE_REF-3 : 
            Displays the fiche on the UI.
           -->
            <p v-html="fiche_referent" />
          </div>
        </div>
      </template>
    </Datatable>

    <div v-if="this.$store.getters.user['is_admin']" id="add_ref_dialog">
      <AddReferent
        :display_add_referent="display_add_referent"
        @get_referents="this.$emit('get_referents')"
      />
    </div>

    <div v-if="this.$store.getters.user['is_admin']" id="del_ref_dialog">
      <!-- 
        ANN-LISTE_REF-11 :
        Displays the component used to delete referent in the Datatable.
      -->
      <DelReferent
        :display_del_referent="display_del_referent"
        :selected_referent="selected_persons"
        @get_referents="this.$emit('get_referents')"
      />
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import HideReferent from "./HideReferent.vue";
import AddReferent from "./AddReferent.vue";
import DelReferent from "./DelReferent.vue";

export default {
  props: ["data", "columns", "fiche_referent", "loading"],

  components: {
    HideReferent,
    AddReferent,
    DelReferent,
  },

  data() {
    return {
      expandedRows: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      hide_ref_id: null,
      selected_persons: [],
      display_add_referent: false,
      display_del_referent: false,
    };
  },
  methods: {
    onRowExpand(event) {
      /* Closes the other hidden row before getting the next fiche (we only store one fiche) */
      this.$parent.get_fiche_referent(event.data.referent_id);
      if (this.expandedRows.length >= 2) {
        this.expandedRows.shift();
      }
    },

    exportCSV() {
      this.$refs.referent_datatable.exportCSV();
    },

    get_referents() {
      this.$parent.get_referents();
    },

    open_add_referent() {
      this.display_add_referent = true;
    },

    open_del_referent() {
      if (this.selected_persons.length != 0) {
        this.display_del_referent = true;
      }
    },

    send_mail(list_person) {
      /*
        ANN-LISTE_REF-9 : 
        Method that add send a mail to every person in the
        var list_person.
      */
      var mail_receiver = "";
      for (var ind_person in list_person) {
        mail_receiver += list_person[ind_person]["mail"];
        mail_receiver += ",";
      }
      if (mail_receiver != "") {
        window.location.href = `mailto:?bcc=${mail_receiver}`;
      }
    },
  },
};
</script>
