<template>
  <div id="edit_ref">
    <!--
        ANN-LISTE_REF-6 :
        Dropdown menu where all available referents are displayed. The admin can
        select one from the list.
        -->
    <Dropdown
      v-model="editor_data['selected_referent']"
      :options="referents"
      optionLabel="person.nom"
      :filter="true"
      :showClear="true"
      placeholder="Sélectionner"
    >
      <template #value="ref">
        <div v-if="ref.value">
          <!-- Displays the first and last name of the selected referent -->
          {{ ref.value.person.nom }} {{ ref.value.person.prenom }}
        </div>
        <span v-else>{{ ref.placeholder }}</span>
      </template>
      <template #option="ref">
        <!-- Displays the first and last name of the referent in the dropdown menu -->
        <div>{{ ref.option.person.nom }} {{ ref.option.person.prenom }}</div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
export default {
  props: ["referents", "editor_data"],

  data() {
    return {};
  },
};
</script>