<template>
  <div id="app">
    <div class="columns is-centered">
      <div class="column is-12">
        <div id="nav" v-if="this.$store.getters.user" style="text-align: left">
          <!-- 
            Displays a navigation bar at the top of the screen.
          -->
          <Menubar :model="items">
            <template #start>
              <!-- 
                Displays the school's logo at the start of the navigation bar.
              -->
              <img
                src="./assets/logo-ecole-negatif.png"
                class="logo"
                alt="IMT Mines d'Albi"
              />
            </template>
            <template #end v-if="current_user">
              <!-- 
                Displays the current user in the right side of the navigation bar.
              -->
              {{ current_user.nom }}
              {{ current_user.prenom }}
              <em class="pi pi-user" />
            </template>
          </Menubar>
          <!-- Display the current user's info at the top right of the UI -->
        </div>
      </div>
    </div>
    <router-view />

    <!-- Put the user's data (retrieved from the DB) and the csrf_token (given by the CAS) 
    in the VueX store if no user is currently in the store -->
    <div v-if="user">
      <div v-if="current_user != user">
        <div v-html="store_user()"></div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      user: null,
      csrf_token: null,
      loading: true,

      items: [
        {
          label: "Accueil",
          to: "/",
        },
        {
          /* ANN-LISTE_REF-1 :
            Display a link to the list of all referents in the navigation bar. 
          */
          label: "Annuaire des référents",
          to: "/annuaire",
        },
        {
          /* ANN-LISTE_ELV-1 :
            Display a link to the list of all students in the navigation bar.
            Only accessible if the user is an admin.
          */
          label: "Annuaire des élèves",
          to: "/annuaire/student",
          visible: () => this.$store.getters.user.is_admin,
        },
        {
          /* FREF-REFERENT-1 :
            Display a lonk in the navigation bar where a referent can see
            a list of their referes. Only accessible if the user is a
            referent.
          */
          label: "Mes Référés",
          to: "/mes-referes",
          visible: () => this.$store.getters.user.is_referent,
        },
        {
          /* ANN-FICHE_REF-4 :
            Display a link in the navigation bar where the user can change
            their fiche referent. Only accessible if the user is a referent.
          */
          label: "Ma Fiche Référent",
          to: "/fiche",
          visible: () => this.$store.getters.user.is_referent,
        },
        {
          /* FREF-REFERE-1 :
            Display a link in the navigation bar where a student with a referent
            can find the informations about the rendez-vous and the referent.
          */
          label: "Mon Référent",
          to: "/mon-referent",
          visible: () =>
            this.$store.getters.user.is_student &&
            this.$store.getters.user.referent,
        },
        {
          /* AUTH-LOGOUT :
            Display a link to the CAS' logout page in the navigation bar. 
          */
          label: "Déconnexion",
          url: process.env.BASE_URL + "accounts/logout",
          visible: () => this.$store.getters.is_logged_in,
        },
        {
          /* AUTH-LOGIN :
            Emergency login button in case the automatic router doesn't work.
          */
          label: "Connexion",
          url: process.env.BASE_URL + "accounts/login",
          visible: () => !this.$store.getters.is_logged_in,
        },
      ],
    };
  },

  async created() {
    /* Load the useful information from the VueX store and Axios requests */
    await Promise.all([
      this.$store.getters.user,
      this.$store.getters.csrf_token,
      this.get_user(),
    ]);
  },

  methods: {
    get_csrf_token() {
      /* 
      AUTH-LOGIN-4 :
      Method that get the csrf_token from the cookie given by the CAS. This token must be sent
      with every request that changes the values in the DB (PUT, POST, PATCH, ...) 
      */
      const csrf = document.cookie.match(
        "(^|;)\\s*csrftoken\\s*=\\s*([^;]+)"
      )[2];
      this.csrf_token = csrf;
    },

    get_user() {
      /*
      AUTH-LOGIN-1 :
      API Call to get the current user's information.
      */
      axios({
        method: "get",
        url: process.env.BASE_URL + "api/auth/whoami/",
      }).then((response) => (this.user = response.data));
    },

    store_user() {
      /*
      Method that register in a Vuex store user information (if a user is logged in) or redirect the anonymous user
      to the CAS login page      
      */
      if (!this.user.is_logged_in) {
        /*
        AUTH-LOGIN-2 :
        Redirects the user to the CAS login page if he/she is not logged in.
        */
        this.$store.dispatch("log_out");
        window.location.href = process.env.BASE_URL + "accounts/login";
      } else if (this.user.is_logged_in) {
        /*
        AUTH-LOGIN-4 :
        If the method 'get_user' returned 'is_logged_in == True', dispatch the user 
        information to a Vuex store.
        */
        this.get_csrf_token();
        this.$store.dispatch("log_in", {
          new_user: this.user,
          new_csrf_token: this.csrf_token,
        });
      }
    },
    log_out() {
      /* Move the user to the CAS logout page and log him out of VueX */
      this.$store.dispatch("log_out");
      window.location.href = process.env.BASE_URL + "accounts/logout";
    },
  },

  computed: {
    current_user() {
      return this.$store.getters.user;
    },
  },
};
</script>



<style lang="scss">
@import "../node_modules/bulma";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  display: flex;
  align-items: center;
}

#user_info {
  padding: 5px;
}

#flex-jc-between {
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 140px;
  vertical-align: middle;
  border-radius: 1px;
  margin-right: 3px;
}
</style>