<template>
  <div>
    <!-- 
        ANN-LISTE_REF-10 :
        Pop up dialog that displays a pop-up window
        where the admin can fill in the information
        of the referent they want to add. The two
        buttons at the bottom of the pop-up either
        cancel and reset the form or send the request
        to Django.
    -->
    <Toast />

    <Dialog
      v-model:visible="display_add_referent"
      :modal="true"
      header="Ajouter un Référent"
      :style="{ width: '450px' }"
      @show="open_form"
      @hide="hide_form"
      class="p-fluid"
    >
      <!-- 
        ANN-LISTE_REF-10 :
          Field for the new referent's civilite. The admin must choose
          one of the options to add the referent. 
      -->
      <div class="p-field">
        <label for="civilite">Civilite</label>
        <Dropdown
          id="civilite"
          v-model="new_referent.civilite"
          :options="['M.', 'MME', 'Autre']"
          placeholder="Sélectionner"
        >
          <template #value="civ">
            <div v-if="civ.value">
              {{ civ.value }}
            </div>
            <div v-else>
              {{ civ.placeholder }}
            </div>
          </template>
        </Dropdown>
        <small class="p-error" v-if="submitted && !new_referent.civilite">
          Le champs Civilité est obligatoire.
        </small>
      </div>

      <!-- 
        ANN-LISTE_REF-10 :
          Field for the new referent's last name. The admin must fill
           this field to add the referent. 
      -->
      <div class="p-field">
        <label for="last_name">Nom</label>
        <InputText
          id="last_name"
          v-model.trim="new_referent.nom"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !new_referent.nom }"
        />
        <small class="p-error" v-if="submitted && !new_referent.nom">
          Le champs Nom est obligatoire.
        </small>
      </div>

      <!-- 
        ANN-LISTE_REF-10 :
          Field for the new referent's first name. The admin must fill
          this field to add the referent. 
      -->
      <div class="p-field">
        <label for="first_name">Prenom</label>
        <InputText
          id="first_name"
          v-model.trim="new_referent.prenom"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !new_referent.prenom }"
        />
        <small class="p-error" v-if="submitted && !new_referent.prenom">
          Le champs Prenom est obligatoire.
        </small>
      </div>

      <!-- 
        ANN-LISTE_REF-10 :
          Field for the new referent's mail. The admin must fill
          this field to add the referent. 
      -->
      <div class="p-field">
        <label for="first_name">E-mail école</label>
        <InputText
          id="mail"
          v-model.trim="new_referent.mail"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !new_referent.mail }"
        />
        <small class="p-error" v-if="submitted && !new_referent.mail">
          Le champs E-mail est obligatoire.
        </small>
      </div>

      <!-- 
        ANN-LISTE_REF-10 :
          Field for the new referent's service. The admin must choose
          one of the options in the Dropdown menu to add the referent. 
      -->
      <div class="p-field">
        <label for="service">Service</label>
        <Dropdown
          id="service"
          v-model="new_referent.service"
          :options="services"
          placeholder="Sélectionner"
        >
          <template #value="service">
            <div v-if="service.value">
              {{ service.value }}
            </div>
            <div v-else>
              {{ service.placeholder }}
            </div>
          </template>
        </Dropdown>
        <small class="p-error" v-if="submitted && !new_referent.service">
          Le champs Service est obligatoire.
        </small>
      </div>

      <template #footer>
        <div id="flex-jc-between">
          <Button
            label="Sauvegarder"
            icon="pi pi-check"
            class="p-button-success"
            @click="save_referent"
          />

          <ProgressSpinner
            style="width: 40px; height: 40px"
            strokeWidth="5"
            animationDuration=".7s"
            v-if="updating == true"
          />

          <Button
            label="Annuler"
            icon="pi pi-times"
            class="p-button-danger"
            @click="hide_form"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["display_add_referent"],

  async beforeMount() {
    await this.get_services();
  },

  data() {
    return {
      submitted: false,
      new_referent: {},
      services: [],
      updating: false,
    };
  },

  methods: {
    open_form() {
      /* Method called when the form to add a referent
      is displayed. It resets the var new_referent so
      that if the user closes the form and reopens it
      the data is cleared. */
      this.submitted = false;
      this.new_referent = {};
    },

    hide_form() {
      /* Method called when the form to add a referent
      is closed (by either clicking outside the box or
      on the close button or on the Cancel button). */
      this.submitted = false;
      this.$parent.display_add_referent = false;
    },

    get_services() {
      /*
      Method that gets the different services the new referent
      can be a part of.
      */
      axios({
        method: "get",
        url: process.env.BASE_URL + "api/annuaire/service/",
      }).then((response) => this.set_services(response.data));
    },

    set_services(array) {
      for (var ind_service in array) {
        this.services.push(array[ind_service]["label"]);
      }
    },

    save_referent() {
      /* ANN-LISTE_REF-10 :
        Method that checks if every field of the form
        were completed before sending a request to Django.
        If Django returns a success, a green message appears
        on screen to notify the user and the referent list
        is updated. Else, the user is notified that something
        did not work.
      */

      if (
        this.new_referent.civilite.trim &&
        this.new_referent.nom.trim &&
        this.new_referent.prenom.trim &&
        this.new_referent.mail.trim &&
        this.new_referent.service.trim
      ) {
        this.submitted = true;
        if (!this.updating) {
          this.updating = true;
          axios
            .patch(
              process.env.BASE_URL + "api/annuaire/referents/add/",
              { new_referent: this.new_referent },
              {
                headers: {
                  "X-CSRFToken": this.$store.getters.csrf_token,
                },
              }
            )
            .then((response) => {
              this.updating = false;
              if (response.data["detail"] == "Status: 200") {
                /* If the response is 200, the database was modified,
              we can close the pop_up, reset the var containing the
              new referent's information, update the referents on the
              user's screen and display a message. */
                this.$parent.display_add_referent = false;
                this.new_referent = {};
                this.$emit("get_referents");
                this.$toast.add({
                  severity: "success",
                  summary: "Modifications enregistrées.",
                  life: 3000,
                });
              } else {
                /* Else, we inform the user that a problem has occured. */
                this.$toast.add({
                  severity: "error",
                  summary: response.data["detail"],
                  life: 3000,
                });
              }
            });
        }
      }
    },
  },
};
</script>