<template>
  <div>
    <Toast />
    <article class="panel is-primary" style="margin-bottom: 0.5rem">
      <!-- 
        FREF-REFERENT-15 :
        Panel header and link to download the tutorial of the application.
      -->
      <p
        class="panel-heading"
        style="cursor: pointer"
        @click="download_file('Tuto_fiche_rdv_ref_ref.pdf')"
      >
        Confirmation des rendez-vous effectués (<i
          class="pi pi-question-circle"
        />
        Tutoriel)
      </p>
      <span
        class="appointment"
        v-for="appointment in list_appointment_not_validated"
        :key="appointment.id"
      >
        <!-- 
          FREF-REFERENT-8 :
          Display all appointment for which the student has submitted a file.
        -->
        <span v-if="appointment['fiche_deposee_par_eleve']">
          <span class="box">
            <p class="appointment_title">
              {{ appointment["student"]["prenom"] }}
              {{ appointment["student"]["nom"] }} -
              {{ appointment["appointment_reference"]["niveau_acad"] }} -
              {{ appointment["appointment_reference"]["titre_rendez_vous"] }}
            </p>
            <span style="text-align: center" class="block">
              <ul>
                <li>Fiche déposée le : {{ appointment["date_depot"] }}</li>
              </ul>
            </span>
            <span>
              <!-- 
                FREF-REFERENT-11 :
                Display a button to validate the appointment file.
              -->
              <Button
                icon="pi pi-check"
                label="Valider la fiche"
                @click="validate_appointment(appointment['id'])"
              />
              <!-- 
                FREF-REFERENT-10 :
                Display a button to invalidate the appointment file.
                -->
              <Button
                icon="pi pi-times"
                label="Refuser la fiche"
                @click="toggle_invalidate_dialog()"
              />
              <Dialog
                class="p-dialog"
                v-model:visible="display_invalidate_dialog"
                header="Confirmation"
                :modal="true"
                :draggable="false"
              >
                <div style="display: block">
                  <div>
                    <i class="p-dialog-icon pi pi-exclamation-triangle" />
                    <span class="p-dialog-message">
                      Etes vous sûr de refuser cette fiche?
                    </span>
                  </div>
                  <Textarea
                    name="commentaire_referent"
                    v-model="commentaire_referent"
                    cols="70"
                    rows="13"
                    placeholder="Raison du refus de la fiche de rendez-vous."
                    :required="true"
                    style="resize: none"
                  />
                </div>
                <template #footer>
                  <Button
                    label="Non"
                    @click="toggle_invalidate_dialog"
                    class="p-button p-confirm-dialog-reject"
                  />
                  <Button
                    label="Oui"
                    @click="
                      invalidate_appointment(
                        appointment['id'],
                        commentaire_referent
                      )
                    "
                    class="p-button p-confirm-dialog"
                    autofocus=""
                  />
                </template>
              </Dialog>
            </span>
            <span>
              <!-- 
                FREF-REFERENT-9 :
                Display a button to download the appointment file.
              -->
              <Button
                icon="pi pi-download"
                label="Consulter la fiche"
                @click="
                  download_appointment_file(
                    appointment['id'],
                    appointment['fiche_deposee_par_eleve']
                  )
                "
              />
            </span>
          </span>
        </span>
      </span>
    </article>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ValidateAppointment",
  props: ["person_id_of_the_referent", "list_appointment_not_validated"],

  data() {
    return {
      display_invalidate_dialog: false,
      publicPath: process.env.BASE_URL,
    };
  },

  methods: {
    change_date_format() {
      for (var appointment of this.list_appointment_not_validated) {
        appointment["date_depot"] = this.reformat_date(
          appointment["date_depot"]
        );
        appointment["appointment_reference"]["date_debut"] = this.reformat_date(
          appointment["appointment_reference"]["date_debut"]
        );
        appointment["appointment_reference"]["date_fin"] = this.reformat_date(
          appointment["appointment_reference"]["date_fin"]
        );
      }
    },

    reformat_date(date_string) {
      var date_array = date_string.split("-");
      return date_array[2] + "/" + date_array[1] + "/" + date_array[0];
    },

    download_appointment_file(id_appointment, file) {
      /*
      FREF-REFERENT-9 :
      Method to get and download one specific appointment.
       */
      var filename = file.split("/media/");
      axios({
        method: "get",
        url:
          process.env.BASE_URL +
          "api/appointments/appointment/serve-appointment-document/",
        headers: { appointment: id_appointment },
        responseType: "blob" // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename[1]); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
      //window.location.href = "api/appointments/appointment/serve_appointment_document/" + id;
    },
    
    download_file(file) {
      var filename = file;
      axios({
        method: "get",
        url:process.env.BASE_URL + "api/appointments/appointment/serve-document/",
        headers: { file: file },
        responseType: "blob" // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    validate_appointment(id_appointment) {
      /*
      FREF-REFERENT-11 :
      Send a request to the API
      "~~/api/appointments/appointment/validate-appointment/" to validate
      one appointment.
      */
      this.$confirm.require({
        message: "Etes vous sûr de valider cette fiche?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          //callback to execute when user confirms the action
          axios({
            method: "get",
            url:
              process.env.BASE_URL +
              "api/appointments/appointment/validate-appointment/",
            headers: { appointment: id_appointment },
          }).then((response) => {
            this.$emit("get_referes");
          });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },

    invalidate_appointment(id_appointment, commentaire_referent) {
      /*
      FREF-REFERENT-10 :
      Send a request to the API
      "~~/api/appointments/appointment/invalidate-appointment/" to invalidate
      one appointment. The referent must have written the reason for refusing
      to validate the appointment.
      */
      if (commentaire_referent) {
        axios
          .patch(
            process.env.BASE_URL +
              "api/appointments/appointment/invalidate-appointment/",
            { commentaire_referent: commentaire_referent },
            {
              headers: {
                appointment: id_appointment,
                "X-CSRFToken": this.$store.getters.csrf_token,
              },
            }
          )
          .then((response) => {
            this.$emit("get_referes");
            this.toggle_invalidate_dialog();
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Précisez la raison de votre refus.",
          life: 3000,
        });
      }
    },

    toggle_invalidate_dialog() {
      this.display_invalidate_dialog = !this.display_invalidate_dialog;
    },
  },
};
</script>