import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'


import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import OverlayPanel from 'primevue/overlaypanel';
import Editor from 'primevue/editor';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Menubar from 'primevue/menubar';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import FileUpload from 'primevue/fileupload';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import Textarea from 'primevue/textarea';
import lazyPlugin from 'vue3-lazy';
import './assets/theme.css'
//import 'primevue/resources/themes/saga-blue/theme.css';       //theme
import 'primevue/resources/primevue.min.css';                 //core css
import 'primeicons/primeicons.css';                           // icons
import loading from './assets/loading.png'
import error from './assets/error.png'

const app = createApp(App)

app.use(router)
app.use(store)
app.use(ToastService)

app.use(PrimeVue, {
    locale: {
        accept: 'Oui',
        reject: 'Non',
        //...
    }
});

app.use(lazyPlugin, {
    loading: loading,
    error: error
  })
app.use(ConfirmationService);

/* This is where we import the PrimeVue component we need to use in the app */
app.component('Datatable', DataTable)
app.component('Column', Column)
app.component('InputText', InputText)
app.component('Dropdown', Dropdown)
app.component('Button', Button)
app.component('OverlayPanel', OverlayPanel)
app.component('Editor', Editor)
app.component('Toast', Toast)
app.component('Menubar', Menubar)
app.component('Dialog', Dialog)
app.component('ConfirmDialog', ConfirmDialog)
app.component('ProgressSpinner', ProgressSpinner)
app.component('FileUpload', FileUpload)
app.component('Textarea', Textarea)


app.mount('#app')