import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";


const store = createStore({
    /*
    AUTH-LOGIN-4 :
    Vuex store that stock user information throughout the whole browsing of the application.
    The informations in the 'user' variable are the same as the ones returned by the 'get_user' method.
    To use one action from the store in the app :
        this.$store.dispatch("action_name", *args)
    To get on variable from the store in the app :
        this.$store.getters.name
    */
    plugins: [createPersistedState({})],
    state() {
        return {
            is_logged_in: false,
            user: null,
            csrf_token: null,
        }
    },
    mutations: {
        //List of all store mutations that are used in the store actions
        LOG_IN(state, payload) {
            state.is_logged_in = true
            state.user = payload.new_user
            state.csrf_token = payload.new_csrf_token
        },
        LOG_OUT(state) {
            state.is_logged_in = false
            state.user = null
            state.csrf_token = null
        },
        SET_CSRF_TOKEN(state, csrf_token) {
            state.csrf_token = csrf_token
        },
        CHANGE_FICHE_REF(state, new_fiche_ref) {
            state.user['fiche_ref'] = new_fiche_ref
        },
    },
    actions: {
        //List of all store methods that can be accessed from the app.
        log_in({ commit }, new_user, new_csrf_token) {
            commit('LOG_IN', new_user, new_csrf_token)
        },
        log_out({ commit }) {
            commit('LOG_OUT')
        },
        set_csrf_token({ commit }, csrf_token) {
            commit('SET_CSRF_TOKEN', csrf_token)
        },
        change_fiche_ref({ commit }, new_fiche_ref) {
            commit('CHANGE_FICHE_REF', new_fiche_ref)
        },
    },
    getters: {
        //List of all store var that can be accessed from the app.
        user: state => state.user,
        is_logged_in: state => state.is_logged_in,
        csrf_token: state => state.csrf_token
    }

})

export default store