<template>
  <div>
    <!--
        ANN-LISTE_ELV-12 :
        The Toast component is to inform the admin about the 
        status of the update.
        The Dialog component is a pop up to ask the user if
        they really want to update the DB, this update is quite
        long so we don't want it to be launched by a missclick.
     -->
    <Toast />

    <Dialog
      v-model:visible="display_update_students"
      :modal="true"
      header="Mettre à jour la liste des élèves"
      :style="{ width: '450px' }"
      @show="open_form"
      @hide="hide_form"
      class="p-fluid"
    >
      <div>
        Voulez-vous mettre à jour la liste des élèves ? Cette action va
        synchroniser la base de données de l'application avec les informations
        présentes dans Synapses.
      </div>
      <template #footer>
        <div id="flex-jc-between">
          <Button
            label="Mettre à jour"
            icon="pi pi-check"
            class="p-button-success"
            @click="update_students"
          />

          <ProgressSpinner
            style="width: 40px; height: 40px"
            strokeWidth="5"
            animationDuration=".7s"
            v-if="updating == true"
          />

          <Button
            label="Annuler"
            icon="pi pi-times"
            class="p-button-danger"
            @click="hide_form"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["display_update_students"],
  data() {
    return { updating: false };
  },
  methods: {
    hide_form() {
      /* Method that hides the pop up if the user clicks either
      outside the box, on the cross or on the cancel button. */
      this.$parent.display_update_students = false;
    },

    update_students() {
      /* ANN-LISTE_ELV-12 :
        Call the API that updates the local DB's data about students.
        Update the displayed students after the update is done.
        If everything went without a problem, a green Toast informs
        the admin, else a red error message appears on screen.
        */
      if (!this.updating) {
        this.updating = true;
        axios
          .patch(
            process.env.BASE_URL + "api/annuaire/students/update-students/",
            {},
            {
              headers: {
                "X-CSRFToken": this.$store.getters.csrf_token,
              },
            }
          )
          .then((response) => {
            this.updating = false;
            this.$emit("get_students");
            this.$parent.display_update_students = false;
            if (response.data["detail"] == "Status: 200") {
              this.$toast.add({
                severity: "success",
                summary: "Modifications enregistrées.",
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: response.data["detail"],
                life: 3000,
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #00b8de;
  }
  40% {
    stroke: #ef7d00;
  }
  66% {
    stroke: #fff;
  }
  80%,
  90% {
    stroke: #0c2340;
  }
}
</style>